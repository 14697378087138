import moment from 'moment'

export const SET_LOADING = 'scorecards/setLoading'
export const SET_DATA = 'scorecards/setData'
export const SET_TABLE_HEADERS = 'scorecards/setTableHeaders'
export const SET_TABLE_ROWS = 'scorecards/setTableRows'
export const SET_FILTER = 'scorecards/setFilter'
export const SET_DATE_RANGE_FILTER = 'scorecards/setDateRangeFilter'
export const CLEAR_FILTERS = 'scorecards/clearFilters'
export const SET_SCORECARD_CONFIGS = 'scorecards/setConfigs'

// Action Creators
export const setLoading = (section, payload) => ({
  type: SET_LOADING,
  section,
  payload,
})
export const setData = (section, payload) => ({
  type: SET_DATA,
  section,
  payload,
})
export const setTableHeaders = (section, payload) => ({
  type: SET_TABLE_HEADERS,
  section,
  payload,
})
export const setTableRows = (section, payload) => ({
  type: SET_TABLE_ROWS,
  section,
  payload,
})
export const setFilter = (section, payload) => ({
  type: SET_FILTER,
  section,
  payload,
})
export const setDateRangeFilter = (payload) => ({
  type: SET_DATE_RANGE_FILTER,
  payload,
})
export const clearFilters = () => ({ type: CLEAR_FILTERS })

// State
export const initialState = {
  data: {
    agents: [],
    playbooks: [],
    targetPlaybook: {},
    tags: [],
    aggregateScores: {},
    tableHeaders: {
      advancedExports: [],
      scorecardDashboard: [],
    },
    tableRows: {
      advancedExports: [],
      scorecardDashboard: [],
    },
    criteriaScoresByMeasure: {},
    aggregateScoresByUser: [],
    aggregateFilters: {},
    currentOrganizationId: '',
    scores: [],
    allScores: [],
    displayNotableCalls: false,
    currentScore: {},
    scorecards: [],
    scorecardOptions: [],
    scorecardConfigs: [],
    scorecardAssociations: [],
    aboveThreshold: [],
    belowThreshold: [],
    targetScorecardMissingCriteria: [],
    targetScorecardConfig: {},
    targetScorecardSection: {},
    callsCount: 0,
    selectedScorecard: {},
    reporting: {},
    reportingByDay: {},
    reportingComparison: {},
  },
  loading: {
    all: false,
    agents: false,
    playbooks: false,
    targetPlaybook: false,
    tags: false,
    aggregateScores: false,
    aggregateScoresByUser: false,
    aggregateFilters: false,
    tableHeaders: {},
    tableRows: {},
    notableCalls: false,
    missingCriteria: false,
    scores: false,
    scoresTable: false,
    currentScore: false,
    scorecards: false,
    scorecardOptions: false,
    scorecardConfigs: false,
    targetScorecardConfig: false,
    targetScorecardSection: false,
    callsCount: false,
    reporting: false,
    reportingComparison: false,
  },
  filters: {
    agents: [],
    groupBy: [],
    metrics: [],
    organization: null,
    playbooks: [],
    scorecards: null,
    tags: [],
    includeManagers: false,
    callDuration: '',
    startDate: moment(Date.now()).startOf('day'),
    endDate: moment(Date.now()).endOf('day'),
  },
}

// Reducer
export default function scorecardsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.section]: action.payload,
        },
      }
    case SET_TABLE_HEADERS:
      return {
        ...state,
        data: {
          ...state.data,
          tableHeaders: {
            ...state.data.tableHeaders,
            [action.section]: action.payload,
          },
        },
      }
    case SET_TABLE_ROWS:
      return {
        ...state,
        data: {
          ...state.data,
          tableRows: {
            ...state.data.tableRows,
            [action.section]: action.payload,
          },
        },
      }
    case SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.section]: action.payload,
        },
      }
    case SET_DATE_RANGE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      }
    case CLEAR_FILTERS:
      return {
        ...state,
        filters: { ...initialState.filters },
      }
    case SET_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.section]: action.payload,
        },
      }
    default:
      return state
  }
}
