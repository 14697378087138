import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { v4 } from 'uuid'
import { capitalize } from 'lodash'
import { Icon, Popup } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
  closeAlert,
  startListeningToAlert,
  stopListeningToAlert,
} from '@/reducers/realtime/realtime.actions'
import { ChatIcon, CloseIcon, PhoneIcon, StopIcon } from '@/components/icons/AlertIcon'
import { GroupedAlerts } from '@/views/RealtimeCoaching/Alerts/AlertSidebar/GroupedAlerts'
import { TimeSinceLastAlert } from '@/views/RealtimeCoaching/Alerts/AlertSidebar/TimeSinceLastAlert'
import { AlertTranscript } from '@/views/RealtimeCoaching/Alerts/AlertSidebar/AlertTranscript'

export const UserAlertBlock = ({ realtimeUserAlerts }) => {
  const dispatch = useDispatch()
  const { alertSidebarCollapsed } = useSelector((state) => state.ui)
  const chats = useSelector((state) => state.realtimeChats)
  const currentUser = useSelector((state) => state.currentUser)
  const { alerts, scrollToAlertTime } = useSelector((state) => state.realtimeUserAlerts)
  const transcriptionScrollTop = useRef(0)
  const scrollToInstanceRef = useRef(null)
  const [expanded, setExpanded] = useState({})
  const [highlightedInstances, setHighlightedInstances] = useState([])

  const toggleExpand = (alertConfigId, intendedAgentUsername) => {
    // Don't toggle the expansion for other users
    Object.keys(alerts).forEach((agentUsername) => {
      if (intendedAgentUsername === agentUsername) {
        setExpanded({
          ...expanded,
          [`${agentUsername}_${alertConfigId}`]: !expanded[`${agentUsername}_${alertConfigId}`],
        })
      }
    })
  }

  const toggleHighlightInstances = (alert) => {
    setHighlightedInstances((prevState) => {
      if (!alert) {
        return []
      }
      const filteredAlerts = prevState.filter(({ alertName }) => alertName !== alert.alertName)
      return [...filteredAlerts, alert]
    })
  }

  useLayoutEffect(() => {
    const alertBox = document.getElementById('alertBox')
    if (typeof alertBox?.scrollTo === 'function') {
      alertBox.scrollTo(0, transcriptionScrollTop.current)
    }
  })

  useEffect(() => {
    try {
      scrollToInstanceRef.current?.scrollIntoView({ block: 'center' })
    } catch {
      // The test for this is flaky in that sometimes this function isn't appended to current. This is a cheap fix.
    }
  }, [scrollToAlertTime])

  const formatAgentNameForTestId = (name) => {
    const lowered = name.toLowerCase()
    return lowered.replace(' ', '-')
  }

  const groupAlerts = (alerts = []) => {
    const newAlerts = []

    for (const alert of alerts) {
      const tempAlert = { ...alert }
      if (
        newAlerts.filter((item) => item.name === tempAlert.name && item.type === tempAlert.type)
          .length > 0
      ) {
        const matchedAlert = newAlerts.filter(
          (item) => item.name === tempAlert.name && item.type === tempAlert.type
        )[0]
        matchedAlert.count++
        matchedAlert.timeStamp.push(alert.timeStamp)
        matchedAlert.created.push(moment.utc(alert.created).local())
      } else {
        tempAlert.count = 1
        newAlerts.push({
          ...tempAlert,
          timeStamp: [tempAlert.timeStamp],
          created: [moment.utc(tempAlert.created).local()],
        })
      }
    }

    return newAlerts
  }

  const startListening = (agentUsername, alertCallId, processingUrl, alertId) => {
    const { username: cloudUsername } = currentUser

    dispatch(stopListeningToAlert())
    toggleHighlightInstances()
    dispatch(
      startListeningToAlert({
        alertCallId,
        agentUsername,
        processingUrl,
        managerUsername: cloudUsername,
        alertId,
      })
    )
  }

  const startChat = ({
    alertId,
    agentId,
    managerId,
    agentLastName,
    agentUsername,
    agentFirstName,
    managerUsername,
    managerFirstName,
    managerLastName,
  }) => {
    dispatch({
      type: 'realtime/beginChat',
      alertId,
      agentId,
      managerId,
      agentUsername,
      agentLastName,
      agentFirstName,
      managerUsername,
      managerFirstName,
      managerLastName,
    })
  }

  return Object.keys(realtimeUserAlerts.alerts).map((username, index) => {
    const {
      agentName,
      managerName,
      alerts,
      callId: alertCallId,
      processingUrl,
      alertId,
      agentId,
      managerId,
      agentUsername,
      managerUsername,
      latestAlertIsoTime,
    } = realtimeUserAlerts?.alerts[username]

    const userHasChatOpen = chats?.chats?.[agentUsername] || null
    const groupedAlerts = groupAlerts(alerts)

    return (
      <div key={`${alertCallId}-${latestAlertIsoTime}`}>
        {!alertSidebarCollapsed && (
          <div className="alert">
            <div className="alert-header">
              <div className="flex align-items-center">
                <div className="agent-name-circle">
                  {agentName && agentName.length > 1 && (
                    <span className="agent-name-circle-text">
                      {agentName
                        ?.split(' ')
                        .map((word) => capitalize(word[0] || ''))
                        .join('')}
                    </span>
                  )}
                </div>
                <div>
                  <span
                    className="agent-name-font"
                    data-testid={`alerted-agent-${formatAgentNameForTestId(agentName)}`}
                  >
                    {agentName}
                  </span>
                </div>
              </div>

              <div className="pointer" onClick={() => dispatch(closeAlert({ username }))}>
                <CloseIcon style={{ width: '100%', height: 'auto' }} />
              </div>
              <TimeSinceLastAlert latestAlertIsoTime={latestAlertIsoTime} />
            </div>

            <GroupedAlerts
              agentUsername={agentUsername}
              groupedAlerts={groupedAlerts}
              expanded={expanded}
              toggleExpand={toggleExpand}
              toggleHighlightInstances={toggleHighlightInstances}
            />

            <div className="alert-buttons-panel">
              <div
                className={
                  !userHasChatOpen
                    ? 'flex pointer alert-action-button'
                    : 'flex pointer alert-action-button alert-action-button-disabled'
                }
                style={{ marginRight: '0px', marginLeft: '10px' }}
                data-testid={`chat-${index}`}
                onClick={() => {
                  if (!userHasChatOpen) {
                    startChat({
                      alertId,
                      agentId,
                      managerId,
                      agentUsername,
                      managerUsername,
                      agentFirstName: agentName.split(' ')[0],
                      agentLastName: agentName.split(' ').slice(1).join(' '),
                      managerFirstName: managerName.split(' ')[0],
                      managerLastName: managerName.split(' ').slice(1).join(' '),
                    })
                  }
                }}
              >
                <div
                  style={{
                    marginTop: '4px',
                  }}
                >
                  <ChatIcon
                    style={{ width: '100%', height: 'auto', background: '#205CAB' }}
                    disabled={userHasChatOpen}
                  />
                </div>
                <div
                  style={{
                    marginTop: '-4px',
                    marginRight: '5px',
                    marginLeft: '5px',
                  }}
                >
                  <span
                    className={
                      userHasChatOpen
                        ? 'alert-action-button-text alert-grey-color'
                        : 'alert-action-button-text alert-black-color'
                    }
                  >
                    {' '}
                    Chat{' '}
                  </span>
                </div>
              </div>

              {realtimeUserAlerts?.listeningToUsername !== username && (
                <div
                  className="flex pointer alert-action-button"
                  data-testid={`listen-${index}`}
                  onClick={() => startListening(username, alertCallId, processingUrl, alertId)}
                >
                  <div
                    style={{
                      marginTop: '4px',
                    }}
                  >
                    <PhoneIcon />
                  </div>
                  <div
                    style={{
                      marginTop: '-4px',
                      marginRight: '5px',
                      marginLeft: '5px',
                    }}
                  >
                    <span className="alert-action-button-text">Listen</span>
                  </div>
                </div>
              )}
              {realtimeUserAlerts?.listeningToUsername === username && (
                <div
                  className="flex pointer alert-action-button"
                  onClick={() => dispatch(stopListeningToAlert())}
                >
                  <div
                    style={{
                      marginTop: '4px',
                    }}
                  >
                    <StopIcon />
                  </div>
                  <div
                    style={{
                      marginTop: '-4px',
                      marginRight: '5px',
                      marginLeft: '5px',
                    }}
                  >
                    <span className="alert-action-button-text">Stop</span>
                  </div>
                </div>
              )}
            </div>
            {realtimeUserAlerts?.listeningToUsername === username && (
              <div className="alert-grid-container">
                <div
                  className="alert-transcription-box"
                  id="alertBox"
                  onScroll={(e) => {
                    // Remember last scroll during re-render.
                    const transcriptionBox = e.target
                    transcriptionScrollTop.current = transcriptionBox?.scrollTop
                  }}
                >
                  <AlertTranscript
                    realtimeUserAlerts={realtimeUserAlerts}
                    highlightedInstances={highlightedInstances}
                    scrollToInstanceRef={scrollToInstanceRef}
                    groupedAlerts={groupedAlerts}
                    toggleHighlightInstances={toggleHighlightInstances}
                  />
                  <div id="transcription-bottom" />
                </div>
              </div>
            )}
          </div>
        )}
        {alertSidebarCollapsed && (
          <div className="alert-collapsed">
            <Popup
              trigger={
                <div>
                  <div className="alert-collapsed-label">
                    <div className="alert-collapsed-count">{alerts?.length}</div>
                  </div>
                  <div
                    className="alert-collapsed-text"
                    data-testid={`alert-for-${agentName[0]}${agentName[1]}`}
                  >
                    {agentName
                      ?.split(' ')
                      .map((word) => capitalize(word[0] || ''))
                      .join('')}
                  </div>
                </div>
              }
            >
              <div>
                <div className="flex space-between">
                  <div>
                    <strong>
                      <span>{agentName}</span>
                    </strong>
                  </div>
                </div>
                <div>
                  {alerts?.map(({ type, name: alertName }) => (
                    <div key={v4()} className="flex">
                      {type === 'positive' && <Icon className="green" name="check circle" />}
                      {type === 'negative' && <Icon className="red" name="exclamation circle" />}
                      {type === 'informative' && <Icon className="blue" name="info circle" />}
                      <span className="blue">{alertName}</span>
                    </div>
                  ))}
                </div>
              </div>
            </Popup>
          </div>
        )}
      </div>
    )
  })
}
