import moment from 'moment'
import { v4 } from 'uuid'
import { closeAlert } from '../reducers/realtime/realtime.actions'
import { apiService } from '../api'
import { fetchActivityLogUsers } from '../reducers/realtime/activityLog/activityLog.actions'
import RTCNotifySound from '../assets/audio/RTCNotifySound.mp3'

let unsupportedBrowser = false
let broadcastChannel = null
let sharedWorker = null

const instanceId = v4()
try {
  sharedWorker = new SharedWorker(new URL('../utils/SharedWorker.js', import.meta.url), {
    type: 'module',
    name: 'baltocloud-rtc-socket-worker',
  })
  sharedWorker.port.start()
} catch (err) {
  console.error('unsupported browser detected, RTC disabled')
  unsupportedBrowser = true
}

export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    // TODO: make fallback middleware that does what rtmSocketMiddleware used to do with a socketClient
    // use that if unsupported browser === true
    if (unsupportedBrowser) {
      return next(action)
    }
    const { type: actionType, ...actionData } = action
    const { username, token, user_id } = getState().currentUser

    switch (actionType) {
      case 'OPEN_RTM_SOCKET':
        if (!broadcastChannel) {
          broadcastChannel = new BroadcastChannel('RtcService')
          broadcastChannel.addEventListener('message', ({ data }) => {
            const { type: broadcastedType, ...broadcastedData } = data

            switch (broadcastedType) {
              case 'CONNECTION_STATUS': {
                dispatch({
                  type: 'realtime/updateSocketStatus',
                  isOpen: broadcastedData.isOpen,
                  maxRetriesExceeded: broadcastedData.maxRetriesExceeded,
                })
                break
              }

              case 'SOCKET_RECEIVED_MESSAGE': {
                const { message } = broadcastedData
                if (message?.data?.stage === 'CHECK_END_CALL') {
                  if (window?.location?.pathname === '/realtime_coaching/activity-log') {
                    dispatch(fetchActivityLogUsers({ alertedUserId: user_id }))
                  }
                  dispatch(closeAlert({ username: message?.user }))
                  dispatch({ type: 'realtime/endChat', username: message?.user })
                } else if (message?.processing_url) {
                  if (message.audible) {
                    new Audio(RTCNotifySound).play()
                  }
                  dispatch({ type: 'realtime/loadAlerts', alert: message })
                } else if (message?.from_user_id && message?.to_user_id) {
                  dispatch({
                    type: 'realtime/addMessageToChat',
                    agentUsername: message?.agent_username,
                    message: message?.message,
                    isMessageFromManager: false,
                    date: moment().format('LT'),
                  })
                } else if ('transcript' in message?.data) {
                  if (message?.user === getState().realtimeUserAlerts?.listeningToUsername) {
                    const formattedTranscription = {
                      text: message?.data?.transcript || '',
                      side: message?.data?.side === 'me' ? 0 : 1,
                      time: new Date(),
                    }
                    dispatch({
                      type: 'realtime/updateAlertTranscription',
                      transcription: formattedTranscription,
                    })
                  }
                }
                break
              }

              default: {
                break
              }
            }
          })
        }

        sharedWorker.port.postMessage({
          token,
          username,
          instanceId,
          type: actionType,
          url: `${apiService.rtc_websocket}/ws/realtime_coaching`,
        })
        break

      case 'REFRESH_USERS_ALERTS':
        sharedWorker.port.postMessage({
          instanceId,
          type: actionType,
          updateData: actionData,
        })
        break

      case 'SEND_CHAT_TO_DESKTOP':
        sharedWorker.port.postMessage({
          instanceId,
          type: actionType,
          chatData: actionData,
        })
        break

      case 'CLOSE_RTM_SOCKET':
        sharedWorker.port.postMessage({
          instanceId,
          type: actionType,
        })
        if (broadcastChannel) {
          broadcastChannel.close()
          broadcastChannel = null
        }
        break
      // if someone logs out, is force logged out because of expired token, etc.
      // explicitly call disconnect which will not reconnect after closing.
      case 'auth/logoutUser':
        sharedWorker.port.postMessage({
          instanceId,
          type: 'CLOSE_RTM_SOCKET',
        })
        if (broadcastChannel) {
          broadcastChannel.close()
          broadcastChannel = null
        }
        break
      default:
        break
    }
    return next(action)
  }
