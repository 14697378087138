import { setOrganizationTags } from './organizationTags.redux'
import { fetchingAPI, apiService } from '../../api'

// TODO: Refactor toggleLoading
export const fetchOrganizationTags =
  ({ organizationId, toggleLoading }) =>
  async (dispatch) => {
    try {
      const tags = await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/tags`,
        'GET',
        dispatch
      )
      dispatch(setOrganizationTags(tags))
      if (toggleLoading) toggleLoading()
    } catch (err) {
      if (toggleLoading) toggleLoading()
    }
  }
