// Action Types
export const SET_USERS = 'organizations/setUsers'
export const SET_ACTIVATION_CODE = 'organizations/setActivationCode'
export const SET_LOADING = 'organizations/setLoading'
export const SET_ACTIVATION_CODES_FOR_ORGANIZATION =
  'organizations/setActivationCodesForOrganization'

// Action Creators
export const setUsers = (payload) => ({ type: SET_USERS, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setActivationCode = (payload) => ({
  type: SET_ACTIVATION_CODE,
  payload,
})
export const setActivationCodesForOrganization = (payload) => ({
  type: SET_ACTIVATION_CODES_FOR_ORGANIZATION,
  payload,
})

// State
export const initialState = {
  activationCode: '',
  loading: {
    userTable: false,
    activationCodeModal: false,
  },
  users: [],
  activationCodesForOrganization: null,
}

// Reducer
export default function organizationUsersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USERS:
      return { ...state, users: action.payload }
    case SET_ACTIVATION_CODE:
      return { ...state, activationCode: action.payload }
    case SET_LOADING:
      return { ...state, loading: { ...state.loading, ...action.payload } }
    case SET_ACTIVATION_CODES_FOR_ORGANIZATION:
      return { ...state, activationCodesForOrganization: action.payload }
    default:
      return state
  }
}
