import React from 'react'
import classNames from 'classnames'

const NoData = ({ text, headerText, position }) => {
  return (
    <div className={classNames('no-data-container', { 'left-aligned': position === 'left' })}>
      <div>
        <h3>{headerText || 'Nothing to display'}</h3>
        <p>{text || 'No data meets the filter criteria'}</p>
      </div>
    </div>
  )
}

export default NoData
