import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'

export const Content = ({ children }) => {
  const { realtime_coaching_access: rtcAccess, real_time_management_access: deprecatedRtcAccess } =
    useSelector((state) => state.currentUser)
  const { sidebarCollapsed, alertSidebarCollapsed } = useSelector((state) => state.ui)

  // Shift pendo icon when sidebar expanded/collapsed
  rtcAccess && !alertSidebarCollapsed
    ? document.body.classList.add('shift-pendo-icon')
    : document.body.classList.remove('shift-pendo-icon')

  return (
    <main
      className={classNames('balto-content', {
        collapse: sidebarCollapsed,
        'alert-sidebar-collapsed': (rtcAccess || deprecatedRtcAccess) && alertSidebarCollapsed,
        'alert-sidebar-expanded': (rtcAccess || deprecatedRtcAccess) && !alertSidebarCollapsed,
      })}
    >
      <div className={classNames('balto-content__inner')}>{children}</div>
    </main>
  )
}
