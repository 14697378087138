import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'semantic-ui-react'
import ReactSelect from 'react-select'
import { v4 } from 'uuid'
import { loadPlaybookBodyByCid } from '@/reducers/realtime/realtime.actions'
import { selectStyles, selectTheme } from '../../helpers/selectHelpers'
import MultiSelect from '../MultiSelect'
import './PlaybookItemSelector.css'

// TODO: Make this an actual instance of an AbstractForm component - it does not belong in the AbstractForm main setup.
export const PlaybookItemSelector = ({
  values,
  label,
  options,
  configPaths,
  playbooks,
  onItemAdded,
  readOnly,
  ...props
}) => {
  const dispatch = useDispatch()
  const { configPathsLoading } = useSelector((state) => state.realtimeNewAlertConfiguration)
  const styles = selectStyles(false)
  styles.container = (provided) => ({
    ...provided,
    minWidth: '190px',
    borderRadius: '4px',
  })

  const itemOptions = [
    {
      value: 'checklist',
      label: 'Checklist',
    },
    {
      value: 'deck',
      label: 'Deck',
    },
    {
      value: 'classified_postcall',
      label: 'Post Call',
    },
    {
      value: 'notifications',
      label: 'Notifications',
    },
    {
      value: 'checklist-timing',
      label: 'Checklist - Timing Configured',
    },
  ]

  const [selectedPlaybook, setSelectedPlaybook] = useState()
  const [selectedPlaybookItem, setSelectedPlaybookItem] = useState()
  const [selectedPlaybookPaths, setSelectedPlaybookPaths] = useState([])

  useEffect(() => {
    if (selectedPlaybook?.value) {
      dispatch(loadPlaybookBodyByCid({ playbookCid: selectedPlaybook?.value }))
    }
  }, [selectedPlaybook])

  function constructPlaybookItemOptions() {
    return selectedPlaybook && selectedPlaybookItem
      ? configPaths[selectedPlaybook.value].paths.filter((item) => {
          switch (selectedPlaybookItem.value) {
            case 'checklist-timing':
              return item.type === 'checklist' && item.required_before
            case 'checklist':
              return item.type === selectedPlaybookItem.value && !item.required_before
            default:
              return item.type === selectedPlaybookItem.value
          }
        })
      : []
  }

  return (
    <div {...props} className={`${props.className} field`}>
      <label style={{ display: 'flex' }}>
        {label}
        {props.required && (
          <div
            style={{
              margin: '-0.2em 0 0 0.2em',
              color: 'var(--error)',
            }}
          >
            *
          </div>
        )}
      </label>
      <div className="main-box">
        <div className="playbook-dropdown">
          <label className="playbook-labels">Select Playbook</label>
          <ReactSelect
            isLoading={configPathsLoading}
            isClearable
            isSearchable
            closeMenuOnSelect
            escapeClearsValue={false}
            hideSelectedOptions={false}
            data-testid="playbook-selector"
            placeholder="Select Playbook"
            backspaceRemovesValue={false}
            options={
              configPaths &&
              Object.keys(configPaths).map((configId) => {
                return {
                  key: configId,
                  label: configPaths[configId].name,
                  value: configId,
                }
              })
            }
            value={selectedPlaybook}
            selection
            clearable
            styles={styles}
            onChange={(item) => {
              setSelectedPlaybook(item)
              setSelectedPlaybookItem(null)
              setSelectedPlaybookPaths(null)
            }}
            theme={(theme) => selectTheme(theme)}
            isDisabled={readOnly}
            {...props}
          />
        </div>
        <div className="flex playbook-dropdown selector-dropdown">
          <div className="type-dropdown">
            <label className="playbook-labels" htmlFor="playbook-item-selector">
              Select Item Type
            </label>
            <ReactSelect
              isClearable
              isSearchable
              closeMenuOnSelect
              placeholder="Select Item Type"
              aria-labelledby="playbook-item-selector"
              data-testid="playbook-item-selector"
              escapeClearsValue={false}
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              options={itemOptions}
              value={selectedPlaybookItem}
              selection
              isDisabled={!selectedPlaybook || readOnly}
              clearable
              styles={styles}
              onChange={(item) => {
                setSelectedPlaybookItem(item)
                setSelectedPlaybookPaths(null)
              }}
              theme={(theme) => selectTheme(theme)}
              {...props}
            />
          </div>
          <div className="item-dropdown">
            <label className="playbook-labels">Select Item(s)</label>
            <MultiSelect
              loading={configPathsLoading}
              placeholder="Select Playbook Items"
              isDisabled={!selectedPlaybookItem}
              value={selectedPlaybookPaths}
              data-testid="playbook-path-selector"
              options={constructPlaybookItemOptions()}
              onChange={(items) => {
                setSelectedPlaybookPaths(items)
              }}
              {...props}
            />
          </div>
        </div>
        <div className="playbook-add-button-div">
          <Button
            data-testid="add-playbook-item"
            type="button"
            disabled={!selectedPlaybookPaths?.length || readOnly}
            onClick={() => {
              // trigger an event.
              if (onItemAdded) {
                const items = []

                for (const path of selectedPlaybookPaths) {
                  items.push({
                    playbook: selectedPlaybook,
                    item: selectedPlaybookItem,
                    path,
                    id: v4(),
                  })
                }

                onItemAdded(items)
              }

              // clear dropdowns
              setSelectedPlaybook(null)
              setSelectedPlaybookItem(null)
              setSelectedPlaybookPaths([])
            }}
            primary
            className="playbook-add-button"
          >
            Add Trigger Item(s)
          </Button>
        </div>
      </div>
    </div>
  )
}
