import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDDLogs } from '@/reducers/integrations/integrations.actions'
import { Button, Form, Icon, Input, Segment } from 'semantic-ui-react'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import moment from 'moment'
import Select from '@/components/forms/Select'
import { setFilter } from '@/reducers/integrations/integrations.redux'
import { apiService } from '@/api'

export const IntegrationEditorLogs = () => {
  const dispatch = useDispatch()

  // get the vars from state
  const { integration, loading, logs, filter } = useSelector((state) => state.integrations)
  const DDlogs = logs.logs

  let env
  if (apiService.web === 'https://web-service.baltocloud.com') {
    env = 'prod'
  } else {
    env = 'staging'
  }

  const columns = [
    {
      accessor: 'timestamp',
      label: 'Date',
      format: (value) => moment(value).format('MMM Do, YYYY hh:mm a'),
    },
    {
      accessor: 'message',
      label: 'Message',
      isSearchable: true,
    },
    {
      accessor: 'attributes.response.detail',
      label: 'Details',
      isSearchable: true,
    },
  ]

  const get_logs = () =>
    dispatch(
      getDDLogs({
        id: integration?.id,
        values: {
          token: integration?.token,
          env,
          from: filter.date_from.toISOString(),
          to: filter.date_to.toISOString(),
          page: { limit: filter.num_logs },
        },
      })
    )

  const handleTimeRangeFilterChange = (time_range) => {
    const date_to = new Date(filter.date_from.valueOf() + time_range * 60000)
    dispatch(setFilter({ time_range, date_to }))
  }

  const handleDateTimeFilterChange = (date_from) => {
    const date_to = new Date(date_from.valueOf() + filter.time_range * 60000)
    dispatch(setFilter({ date_to, date_from }))
  }

  const handleNumLogsFilterChange = (value) => {
    dispatch(setFilter(value))
  }

  const displayDatetime = () => {
    let timezone_offset
    try {
      timezone_offset = filter.date_from.getTimezoneOffset()
    } catch (err) {
      timezone_offset = 0
    }
    const date_from = new Date(filter.date_from - timezone_offset * 60000)
    return date_from.toJSON().substring(0, 16)
  }

  return (
    <>
      <Form data-testid="integration-log-filters" onSubmit={get_logs}>
        <div className="integration-filter-grid">
          <Form.Field>
            <label>Start Date</label>
            <Input
              type="datetime-local"
              placeholder="Start date"
              className="integration-datetime-local"
              value={displayDatetime()}
              onChange={(selection) => {
                handleDateTimeFilterChange(new Date(selection.target.value))
              }}
            />
          </Form.Field>

          <Form.Field>
            <label>Time Range</label>
            <Select
              placeholder="Select Time Range"
              options={[
                { value: 1, label: '1 minute' },
                { value: 2, label: '2 minutes' },
                { value: 5, label: '5 minutes' },
                { value: 10, label: '10 minutes' },
                { value: 15, label: '15 minutes' },
              ]}
              value={filter.time_range}
              onChange={(option, action) => {
                if (action.action === 'clear') {
                  handleTimeRangeFilterChange(0)
                } else {
                  handleTimeRangeFilterChange(option.value)
                }
              }}
            />
          </Form.Field>

          <Form.Field>
            <label>Number of Logs</label>
            <Input
              type="number"
              placeholder="Number of logs"
              value={filter.num_logs}
              onChange={(num) => {
                handleNumLogsFilterChange({ num_logs: num.target.value })
              }}
              error={filter.num_logs < 1}
            />
            {filter.num_logs < 1 && (
              <div className="error-input">
                <Icon name="warning sign" color="red" />
                <span>Number must be greater than 0</span>
              </div>
            )}
          </Form.Field>

          <Form.Field>
            <label className="visibility-hidden">&nbsp;</label>
            <div className="filter-buttons">
              <Button data-testid="logs-filter-button" className="primary-button" type="submit">
                Filter Logs
              </Button>
            </div>
          </Form.Field>
        </div>
      </Form>

      <Segment className="not-padded">
        <AdvancedTable
          defaultOrderBy="timestamp"
          loading={loading}
          columns={columns}
          rows={DDlogs}
          stickyHeader
          pagination
          noDataHeader="No Logs to Display"
          noDataText="Click [Filter Logs] or change search parameters"
        />
      </Segment>
    </>
  )
}

export default IntegrationEditorLogs
