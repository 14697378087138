import React from 'react'
import { Button, Icon, Popup } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'

import { openModal, closeModal } from '../../../reducers/ui/ui.redux'
import { BasicModal } from './BasicModal'

const ButtonWrapper = ({ popup, modalTitle, children }) => {
  return popup ? <Popup on="hover" content={modalTitle} basic trigger={children} /> : children
}

export const ButtonAndFormModal = ({
  popup = false,
  buttonLabel,
  modalId,
  modalTitle,
  icon,
  modalProps = {},
  buttonProps = {},
  form,
  disabled,
}) => {
  const dispatch = useDispatch()
  const { currentlyOpenModalId } = useSelector((state) => state.ui)

  const handleOpen = () => dispatch(openModal(modalId))
  const handleClose = () => dispatch(closeModal())

  return (
    <>
      <ButtonWrapper popup={popup} modalTitle={modalTitle}>
        <Button
          primary={!icon}
          type="button"
          data-testid={`${modalId}-button`}
          disabled={disabled}
          onClick={handleOpen}
          {...buttonProps}
        >
          {icon && <Icon name={icon} style={{ margin: 0 }} />}
          {buttonLabel}
        </Button>
      </ButtonWrapper>

      {currentlyOpenModalId === modalId && (
        <BasicModal
          data-testid={`${modalId}-modal`}
          title={modalTitle}
          onClose={() => dispatch(closeModal())}
          show={currentlyOpenModalId === modalId}
          size="small"
          {...modalProps}
        >
          {React.cloneElement(form, { onClose: handleClose, modalClose: handleClose })}
        </BasicModal>
      )}
    </>
  )
}
