import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'
import { datadogRum } from '@datadog/browser-rum'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

import { Toasts } from '@/components/toasts/Toasts'
import store from './store'
import App from './App'
import { buildUserAttributes } from './utils/launchDarkly'

import './assets/styles/variables.scss'
import './assets/styles/theme.scss'
import './assets/styles/main.scss'

export const history = createBrowserHistory()
const ErrorBoundary = React.lazy(() => import('./components/layout/ErrorBoundary'))
const RtcSocketProvider = React.lazy(() => import('./components/providers/RtcSocketProvider'))
const OrganizationsProvider = React.lazy(() =>
  import('./components/providers/OrganizationsProvider')
)
const CustomBrandingProvider = React.lazy(() =>
  import('./components/providers/CustomBrandingProvider')
)

// Creating the portal here so we only make 1, and all the draggables can share it
if (document.body) {
  const portal = document.createElement('div')
  portal.classList.add('dnd-portal')
  document.body.appendChild(portal)
}
const currentAppVersion = process.env.COMMIT_HASH
const currentEnv = process.env.NODE_ENV

console.info(`Version: ${currentAppVersion}`)

if (currentEnv === 'production') {
  datadogRum.init({
    applicationId: process.env.DATADOG_RUM_APP_ID,
    clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'balto-cloud-web',
    version: currentAppVersion,
    env: process.env.NODE_ENV,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogRum.startSessionReplayRecording()
}

function renderApp(NextApp) {
  const user = JSON.parse(localStorage.getItem('User'))
  const ldUser = user ? buildUserAttributes(user) : { key: 'Anonymous' }

  const launchDarklyConfig = {
    clientSideID: process.env.LAUNCH_DARKLY_CLIENTSIDE_ID,
    user: ldUser,
    options: {
      ...(process.env.LAUNCH_DARKLY_STREAMING_OPTION !== undefined && {
        streaming: process.env.LAUNCH_DARKLY_STREAMING_OPTION,
      }),
    },
  }
  const LDApp = withLDProvider(launchDarklyConfig)(() => (
    <Provider store={store}>
      <React.Suspense fallback={<Loader active />}>
        <ErrorBoundary>
          <RtcSocketProvider />
          <OrganizationsProvider />
          <CustomBrandingProvider />
          <Toasts />
          <Router history={history}>{NextApp ? <NextApp /> : <App />}</Router>
        </ErrorBoundary>
      </React.Suspense>
    </Provider>
  ))

  ReactDOM.render(<LDApp />, document.getElementById('content') || document.createElement('div'))
}

renderApp()

// Enable Webpack hot module replacement for components
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App.js', () => {
    const nextApp = require('./App').default
    renderApp(nextApp)
  })
}
