import React, { useState, useEffect } from 'react'
import { Table, Icon, Loader, Popup } from 'semantic-ui-react'
import classNames from 'classnames'
import { toLower, isEmpty } from 'lodash'
import uuid from 'uuid/v4'

import { AdvancedTablePagination } from './AdvancedTablePagination'
import { AdvancedTableHeader } from './AdvancedTableHeader'
import NoData from '../../NoData'
import { getComparator, stableSort } from './helpers'

import '../AdvancedTable.css'

export const AdvancedTable = ({
  rows = [],
  columns = [],
  loading = false,
  loadingText,
  actions = [],
  defaultOrderBy = 'id',
  defaultOrder,
  defaultSortEnabled = true,
  index = 'id',
  stickyHeader = false,
  displayHeader = true,
  rowAction,
  pagination = false,
  rowsPerPage = 20,
  smallActionColumn = false,
  stickyAction = false,
  truncate = false,
  wrapColumnContent = true,
  selectable = true,
  noDataText,
  noDataHeader,
  striped = true,
  testid = 'advanced-table',
}) => {
  const [activePage, setActivePage] = useState(1)
  const [order, setOrder] = useState(defaultOrder || 'asc')
  const [orderBy, setOrderBy] = useState(defaultOrderBy || 'id')
  const [searchFilters, setSearchFilters] = useState({})

  useEffect(() => {
    // Reset table if there's a reload
    if (loading) {
      setSearchFilters({})
      setActivePage(1)
    }
  }, [loading])

  // Update the default order by if it has changed between renders
  useEffect(() => {
    setOrderBy(defaultOrderBy)
  }, [defaultOrderBy])

  const handleRequestSort = (event, accessor) => {
    const isAsc = orderBy === accessor && order === 'asc'

    // Reset active page on sort
    setActivePage(1)
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(accessor)
  }

  const updateSearch = (accessor, value) => {
    // Reset active page on search
    setActivePage(1)
    setSearchFilters((prevState) => ({
      ...prevState,
      [accessor]: value,
    }))
  }

  if (loading) {
    return (
      <div className="empty-table">
        <Loader active data-testid="loader">
          {loadingText || ''}
        </Loader>
      </div>
    )
  }

  if (rows.length === 0) {
    return (
      <div className="empty-table" data-testid="empty-table">
        <NoData text={noDataText} headerText={noDataHeader} />
      </div>
    )
  }

  // Filter all rows by search value
  const searchFilteredRows = rows.filter((row) => {
    if (isEmpty(searchFilters)) return row

    return Object.keys(searchFilters).every((accessor) => {
      let filterValue = row[accessor]
      if (filterValue?.value) {
        filterValue = filterValue.value
      }
      return toLower(filterValue).includes(toLower(searchFilters[accessor]))
    })
  })

  // Find pagination values
  const count = searchFilteredRows.length
  const calculatedRowsPerPage = pagination ? rowsPerPage : count
  const totalPages = pagination ? Math.ceil(count / calculatedRowsPerPage) : 1

  // Apply sort and pagination
  let filteredRows
  if (defaultSortEnabled) {
    filteredRows = stableSort(searchFilteredRows, getComparator(order, orderBy)).slice(
      (activePage - 1) * calculatedRowsPerPage,
      activePage * calculatedRowsPerPage
    )
  } else {
    filteredRows = searchFilteredRows.slice(
      (activePage - 1) * calculatedRowsPerPage,
      activePage * calculatedRowsPerPage
    )
  }

  return (
    <>
      <div className="scrollable-table">
        <Table
          basic={striped && 'very'}
          striped={striped}
          selectable={selectable}
          className="advanced-table"
          data-testid={testid}
        >
          {displayHeader && (
            <AdvancedTableHeader
              actions={actions}
              columns={columns}
              orderBy={orderBy}
              order={order}
              onRequestSort={handleRequestSort}
              smallActionColumn={smallActionColumn}
              stickyHeader={stickyHeader}
              displayHeader={displayHeader}
              truncate={truncate}
              stickyAction={stickyAction}
              updateSearch={updateSearch}
              wrapColumnContent={wrapColumnContent}
              searchFilters={searchFilters}
            />
          )}

          <Table.Body>
            {filteredRows.length === 0 ? (
              <Table.Row className="no-hover">
                <Table.Cell colSpan={columns.length + (actions ? 1 : 0)}>
                  <div className="empty-table">
                    <NoData />
                  </div>
                </Table.Cell>
              </Table.Row>
            ) : (
              filteredRows.map((row) => {
                return (
                  <Table.Row
                    key={index === 'uuid' ? uuid() : `${row[index]}`}
                    data-testid={row[index]}
                    {...row.rowProps}
                  >
                    {columns.map((column) => {
                      if (row[column.accessor]?.as) {
                        return (
                          <Table.Cell
                            key={column.accessor}
                            data-testid={`custom-component-${column.accessor}`}
                            {...row[column.accessor].cellProps}
                            className={classNames(
                              {
                                'sticky-column': column.sticky,
                              },
                              `${row[column.accessor].cellProps?.className}`,
                              row[column.accessor].cellProps?.className
                            )}
                          >
                            {row[column.accessor].as}
                          </Table.Cell>
                        )
                      }
                      return (
                        <Table.Cell
                          key={column.accessor}
                          data-testid="table-cell"
                          onClick={(e) =>
                            rowAction && !column.notClickable ? rowAction(e, row) : null
                          }
                          className={classNames({
                            'clickable-row': !!rowAction,
                            'no-wrap': wrapColumnContent,
                            'sticky-column': column.sticky,
                          })}
                        >
                          {column.clickableRowLink ? (
                            <a
                              href={`/${column.clickableRowLink.prefix}/${
                                row[column.clickableRowLink.accessor]
                              }${
                                column.clickableRowLink.suffix ? column.clickableRowLink.suffix : ''
                              }`}
                              className="table-link"
                            >
                              {row[column.accessor]}
                            </a>
                          ) : column.format ? (
                            column.format(row[column.accessor], row)
                          ) : (
                            row[column.accessor]
                          )}
                        </Table.Cell>
                      )
                    })}
                    {!!actions.length && (
                      <Table.Cell
                        style={{ textAlign: 'center' }}
                        className={`${stickyAction ? 'sticky-action' : ''}`}
                      >
                        {actions.map((action) => (
                          <Popup
                            basic
                            key={action.popupLabel ? action.popupLabel(row) : action.label}
                            content={action.popupLabel ? action.popupLabel(row) : action.label}
                            trigger={
                              action.trigger ? (
                                action.trigger(row)
                              ) : (
                                <button
                                  type="button"
                                  id={`${action.label}-button-${row[index]}`}
                                  key={action.label}
                                  data-testid={`action-button-${action.label}`}
                                  className="ui icon button minimal-button"
                                  onClick={(e) => action.fn(e, row)}
                                >
                                  <Icon name={action.icon} className={action.icon} />
                                </button>
                              )
                            }
                          />
                        ))}
                      </Table.Cell>
                    )}
                  </Table.Row>
                )
              })
            )}
          </Table.Body>
        </Table>
      </div>
      {pagination && (
        <AdvancedTablePagination
          activePage={activePage}
          rowsPerPage={calculatedRowsPerPage}
          count={count}
          totalPages={totalPages}
          setActivePage={setActivePage}
        />
      )}
    </>
  )
}
