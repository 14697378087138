import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Icon } from 'semantic-ui-react'
import classNames from 'classnames'
import { collapseAlertSidebar, expandAlertSidebar } from '@/reducers/ui/ui.redux'
import { UserAlertBlock } from '@/views/RealtimeCoaching/Alerts/AlertSidebar/UserAlertBlock'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/solid'
import { ChatPanel } from '../ChatPanel'
import '../../realtimeCoaching.scss'

export const AlertSidebar = () => {
  const dispatch = useDispatch()
  const realtimeUserAlerts = useSelector((state) => state.realtimeUserAlerts)
  const { isOpen: rtcSocketIsOpen, maxRetriesExceeded } = useSelector(
    (state) => state.realtimeSocket
  )
  const { alertSidebarCollapsed } = useSelector((state) => state.ui)

  const collapseSidebar = () => {
    dispatch(collapseAlertSidebar())
  }
  const expandSidebar = () => {
    dispatch(expandAlertSidebar())
  }

  return (
    <div className={classNames('alert-sidebar', { collapsed: alertSidebarCollapsed })}>
      <div
        className="screen-height"
        onClick={() => {
          if (alertSidebarCollapsed) {
            expandSidebar()
          }
        }}
      >
        {alertSidebarCollapsed && (
          <div className="rtc-expand-container">
            <ChevronDoubleLeftIcon className="rtc-expand-icon" />
          </div>
        )}
        <div className="flex padding-20">
          <div className="flex">
            {!alertSidebarCollapsed && (
              <ChevronDoubleRightIcon
                className="rtc-collapse-icon"
                data-testid="rtc-collapse-icon"
                onClick={collapseSidebar}
              />
            )}
            <Icon
              className={alertSidebarCollapsed ? 'bell-collapsed' : 'bell-expanded'}
              name={rtcSocketIsOpen ? 'bell outline' : 'bell'}
              data-testid="bell"
            />
            {!alertSidebarCollapsed && (
              <h4 className="margin-top-0">
                Alerts{' '}
                {!rtcSocketIsOpen && (
                  <>
                    <br />
                    <span style={{ marginLeft: '-37px' }}>(disconnected)</span>
                  </>
                )}
              </h4>
            )}
          </div>
        </div>
        <div className="alert-sidepanel">
          <UserAlertBlock realtimeUserAlerts={realtimeUserAlerts} />
          {!alertSidebarCollapsed &&
            realtimeUserAlerts &&
            Object.keys(realtimeUserAlerts.alerts).length === 0 && (
              <div className="no-alert-vertical-center" data-testid="no-alerts">
                <div className="text-align-center">
                  {rtcSocketIsOpen ? (
                    <>
                      <Icon name="bell outline" />
                      <div>You have no alerts</div>
                    </>
                  ) : (
                    <>
                      <h2>Disconnected</h2>
                      <div data-testid="rtc-disconnected-message">
                        {maxRetriesExceeded
                          ? `Unable to connect to Real-Time Coaching service. If the issue persists,
                          reach out to support@baltosoftware.com.`
                          : `Attempting to reconnect. This could take up to 30 seconds.
                          If the issue persists, reach out to support@baltosoftware.com.`}
                      </div>
                      <Button
                        color="blue"
                        loading={!maxRetriesExceeded}
                        disabled={!maxRetriesExceeded}
                        className="rtc-reconnect-button"
                        data-testid="rtc-reconnect-button"
                        onClick={() => dispatch({ type: 'OPEN_RTM_SOCKET' })}
                      >
                        Retry
                      </Button>
                    </>
                  )}
                </div>
              </div>
            )}
        </div>
        <div
          className="collapse-button"
          data-testid="toggle-sidebar"
          onClick={() => {
            if (!alertSidebarCollapsed) {
              collapseSidebar()
            } else {
              expandSidebar()
            }
          }}
        />
        {!alertSidebarCollapsed && <ChatPanel />}
      </div>
    </div>
  )
}
