import pluralize from 'pluralize'

const transformSectionIssues = (section_suggestions, section, entryIssueCount) => {
  pluralize.addIrregularRule('the', 'each')
  switch (section) {
    case 'checklist': {
      const issuesList = Object.keys(section_suggestions).map((issueName) => {
        switch (issueName) {
          case 'no_win_marker':
            return {
              headline: 'No win marker',
              detail:
                'Setting a win item enables helpful analysis and visually rewards your reps when they hit it.',
              order: 2,
            }
          case 'variety_check':
            return {
              headline: 'No content variety',
              detail: "Having a mix of questions and statements improves reps' conversations",
              order: 3,
            }
          case 'suggested_length': {
            const headline = section_suggestions[issueName].max_length ? 'Too long' : 'Too short'
            return {
              headline,
              detail: `Checklists perform best when they have between 4 and 12 items, but you have ${section_suggestions[issueName].current_length}.`,
              order: 1,
            }
          }
          case 'overlapping_items': {
            return {
              headline: 'Overlapping items',
              detail:
                "Some checklist items sound very similar. Consider rephrasing to avoid accidental triggering and to ensure accurate reporting. Hover over the items' warning signs for more detail.",
              order: 4,
            }
          }
          default:
            return null
        }
      })
      if (entryIssueCount > 0) {
        issuesList.push({
          headline: `${pluralize('item', entryIssueCount, true)} with issues`,
          detail: `Hover over ${pluralize(
            'the',
            entryIssueCount
          )} item's warning sign to see what's wrong.`,
          order: 5,
        })
      }
      return issuesList
    }
    // so far only checklist (win marker, variety, length, similar phrases) and deck (repeat AI library, similar phrases, shared keywords)
    // can have section-wide issues besides issues w/ indiv items
    case 'classified_postcall':
    case 'notifications':
    case 'postcall':
    case 'static':
    case 'summary':
    case 'deck': {
      const issuesList = Object.keys(section_suggestions).map((issueName) => {
        switch (issueName) {
          case 'repeat_ai_library': {
            return {
              headline: 'Repeat AI library items',
              detail:
                "Some decklist items share the same AI Library category as their trigger. Consider setting different triggers to avoid multiple prompts and to ensure accurate reporting. Hover over the items' warning signs for more detail.",
              order: 3,
            }
          }
          case 'overlapping_items': {
            return {
              headline: 'Overlapping items',
              detail:
                "Some decklist items sound very similar. Consider rephrasing to avoid accidental triggering and to ensure accurate reporting. Hover over the items' warning signs for more detail.",
              order: 4,
            }
          }
          case 'shared_keywords': {
            return {
              headline: 'Shared keywords',
              detail:
                "Some decklist items share custom keywords in their triggers. Consider altering keywords to avoid accidental triggering and to ensure accurate reporting. Hover over the items' warning signs for more detail.",
              order: 5,
            }
          }
          default:
            return null
        }
      })
      if (entryIssueCount > 0) {
        issuesList.push({
          headline: `${pluralize('item', entryIssueCount, true)} with issues`,
          detail: `Hover over ${pluralize(
            'the',
            entryIssueCount
          )} item's warning sign to see what's wrong.`,
          order: 6,
        })
      }
      return issuesList
    }
    default:
      return null
  }
}

// Creates a data structure which is ultimately rendered by EntryIssues
// for more information on the input data structure have a look at helpers.py
export const transformEntryIssues = (entries, section) => {
  switch (section) {
    case 'checklist': {
      const entryIssuesObj = {}
      Object.keys(entries).forEach((entry) => {
        if (entries[entry].name) {
          entryIssuesObj[entry] = Object.keys(entries[entry].name).map((issueName) => {
            switch (issueName) {
              case 'vocab_check': {
                return {
                  headline: 'Balto will not understand',
                  detail: entries[entry].name[issueName].map((item) => item).join(', '),
                  order: 1,
                  fieldName: 'name',
                }
              }
              case 'suggested_length': {
                return {
                  detail: 'Item exceeds suggested length of 150 characters',
                  order: 2,
                  fieldName: 'name',
                }
              }
              case 'suggested_word_count': {
                return {
                  detail:
                    'Balto works best when checklist items are at least 5 words long, not including words inside brackets',
                  order: 3,
                  fieldName: 'name',
                }
              }
              case 'brackets': {
                return {
                  headline: `Having more than two ${issueName} reduces Balto's accuracy`,
                  detail: entries[entry].name[issueName].map((item) => item).join(', '),
                  order: 4,
                  fieldName: 'name',
                }
              }
              case 'ellipses':
              case 'underscores': {
                return {
                  detail: `Having more than two ${issueName} reduces Balto's accuracy`,
                  order: 5,
                  fieldName: 'name',
                }
              }
              case 'asterisks': {
                return {
                  detail: 'Having more than two pairs of asterisks may give too much flexibility',
                  order: 6,
                  fieldName: 'name',
                }
              }
              case 'special_characters': {
                return {
                  detail:
                    'Balto works best when you limit your usage of wildcards like brackets, underscores, and ellipses',
                  order: 7,
                  fieldName: 'name',
                }
              }
              case 'punctuation': {
                return {
                  detail:
                    'There appear to be several sentences here. Checklist items work best when they are one or two sentences',
                  order: 8,
                  fieldName: 'name',
                }
              }
              case 'overlapping': {
                const overlapping_items = entries[entry].name[issueName]
                let item_msg = `"${overlapping_items[0]}"`
                if (overlapping_items.length > 1) {
                  item_msg += ` and ${overlapping_items.length - 1} other ${pluralize(
                    'item',
                    overlapping_items.length - 1
                  )}`
                }
                return {
                  detail: `This item overlaps with item ${item_msg}`,
                  order: 9,
                  fieldName: 'name',
                }
              }
              default: {
                return {}
              }
            }
          })
        }
      })
      return entryIssuesObj
    }
    case 'summary':
    case 'classified_postcall':
    case 'postcall':
    case 'static':
    case 'deck': {
      const entryIssuesObj = {}
      Object.keys(entries).forEach((entry) => {
        entryIssuesObj[entry] = []
        Object.keys(entries[entry]).forEach((issueField) => {
          if (issueField === 'name') {
            Object.keys(entries[entry][issueField]).forEach((issueName) => {
              if (issueName === 'vocab_check') {
                entryIssuesObj[entry].push({
                  headline: 'Balto will not understand',
                  detail: entries[entry][issueField][issueName].map((item) => item).join(', '),
                  order: 10,
                  fieldName: 'name',
                })
              } else if (issueName === 'overlapping') {
                const overlapping_items = entries[entry][issueField][issueName]
                let item_msg = `"${overlapping_items[0]}"`
                if (overlapping_items.length > 1) {
                  item_msg += ` and ${overlapping_items.length - 1} other ${pluralize(
                    'item',
                    overlapping_items.length - 1
                  )}`
                }
                entryIssuesObj[entry].push({
                  detail: `This item overlaps with item ${item_msg}`,
                  order: 9,
                  fieldName: 'name',
                })
              } else if (issueName === 'suggest_ai') {
                const suggested_category = entries[entry].name[issueName]
                entryIssuesObj[entry].push({
                  detail: `Consider using AI library topic: ${suggested_category.name}`,
                  order: 10,
                  fieldName: 'name',
                  krid: suggested_category.krid,
                })
              }
            })
          } else if (issueField === 'transcription_classifier') {
            Object.keys(entries[entry][issueField]).forEach((issueName) => {
              if (issueName === 'repeat_ai') {
                const repeat_items = entries[entry][issueField][issueName].repeat_entries
                const ai_category = entries[entry][issueField][issueName].category
                let item_msg = `"${repeat_items[0]}"`
                if (repeat_items.length > 1) {
                  item_msg += ` and ${repeat_items.length - 1} other ${pluralize(
                    'item',
                    repeat_items.length - 1
                  )}`
                }
                entryIssuesObj[entry].push({
                  detail: `This item uses AI library category ${ai_category} along with item ${item_msg}`,
                  order: 8,
                  fieldName: 'transcription_classifier',
                })
              }
            })
            // currently we only have issues with decklist
            // vocab so we can just check for presence of display
          } else if (issueField === 'display') {
            Object.keys(entries[entry].display.closing_loop.decklist.entries).forEach((itemId) => {
              const {
                vocab_check,
                asterisks,
                brackets,
                ellipses,
                punctuation,
                special_characters,
                underscores,
              } = entries[entry].display.closing_loop.decklist.entries[itemId].name

              if (vocab_check) {
                entryIssuesObj[entry].push({
                  headline: 'Balto will not understand in Decklist',
                  detail: vocab_check.map((item) => item).join(', '),
                  order: 11,
                  fieldName: `display.closing_loop.decklist.entries.${itemId}.name`,
                })
              }
              if (asterisks) {
                entryIssuesObj[entry].push({
                  headline: 'Having more than two pairs of asterisks may give too much flexibility',
                  detail: asterisks.map((item) => item).join(', '),
                  order: 12,
                  fieldName: `display.closing_loop.decklist.entries.${itemId}.name`,
                })
              }
              if (brackets) {
                entryIssuesObj[entry].push({
                  detail: 'Having more than two pairs of brackets may give too much flexibility',
                  order: 13,
                  fieldName: `display.closing_loop.decklist.entries.${itemId}.name`,
                })
              }
              if (ellipses) {
                entryIssuesObj[entry].push({
                  detail: 'Having more than two pairs of ellipses may give too much flexibility',
                  order: 14,
                  fieldName: `display.closing_loop.decklist.entries.${itemId}.name`,
                })
              }
              if (underscores) {
                entryIssuesObj[entry].push({
                  detail: 'Having more than two pairs of underscores may give too much flexibility',
                  order: 15,
                  fieldName: `display.closing_loop.decklist.entries.${itemId}.name`,
                })
              }
              if (punctuation) {
                entryIssuesObj[entry].push({
                  detail:
                    'There appear to be several sentences here. Checklist items work best when they are one or two sentences',
                  order: 16,
                  fieldName: `display.closing_loop.decklist.entries.${itemId}.name`,
                })
              }
              if (special_characters) {
                entryIssuesObj[entry].push({
                  detail:
                    'Balto works best when you limit your usage of wildcards like brackets, underscores, and ellipses',
                  order: 17,
                  fieldName: `display.closing_loop.decklist.entries.${itemId}.name`,
                })
              }
            })
          } else if (issueField === 'trigger' && entries[entry][issueField].phrases) {
            Object.keys(entries[entry][issueField].phrases).forEach((issueName) => {
              if (issueName === 'vocab_check') {
                entryIssuesObj[entry].push({
                  headline: 'Balto will not understand',
                  detail: entries[entry].trigger.phrases.vocab_check.map((item) => item).join(', '),
                  order: 18,
                  fieldName: 'trigger.phrases',
                })
              } else if (issueName === 'shared_keywords') {
                const overlapping_items = Object.values(
                  entries[entry][issueField].phrases[issueName]
                ).map((x) => x.name)
                let item_msg = `"${overlapping_items[0]}"`
                if (overlapping_items.length > 1) {
                  item_msg += ` and ${overlapping_items.length - 1} other ${pluralize(
                    'item',
                    overlapping_items.length - 1
                  )}`
                }
                entryIssuesObj[entry].push({
                  detail: `This item shares keywords with item ${item_msg}`,
                  order: 19,
                  fieldName: 'trigger.phrases',
                })
              } else if (issueName === 'suggest_ai') {
                const suggested_category = entries[entry][issueField].phrases[issueName]
                entryIssuesObj[entry].push({
                  detail: `Consider using AI library topic: ${suggested_category.name}`,
                  order: 20,
                  fieldName: 'trigger.phrases',
                  krid: suggested_category.krid,
                })
              }
            })
          }
        })
      })
      return entryIssuesObj
    }
    case 'notifications': {
      const entryIssuesObj = {}
      Object.keys(entries).forEach((entry) => {
        entryIssuesObj[entry] = []
        Object.keys(entries[entry]).forEach((issueField) => {
          if (issueField === 'name') {
            if (entries[entry].name.suggested_length)
              entryIssuesObj[entry].push({
                detail: '"Name" exceeds suggested length of 5 words',
                order: 2,
                fieldName: 'name',
              })
            if (entries[entry].name.vocab_check) {
              entryIssuesObj[entry].push({
                headline: 'Balto will not understand',
                detail: entries[entry].name.vocab_check.map((item) => item).join(', '),
                order: 1,
                fieldName: 'name',
              })
            }
          } else if (issueField === 'reason') {
            entryIssuesObj[entry].push({
              detail: '"Message" exceeds suggested length of 13 words',
              order: 2,
              fieldName: 'display.reason',
            })
          } else if (issueField === 'replacement') {
            if (entries[entry].replacement.suggested_length)
              entryIssuesObj[entry].push({
                detail: '"Replacement" exceeds suggested length of 9 words',
                order: 2,
                fieldName: 'display.replacement',
              })
            if (entries[entry].replacement.missing_reason)
              entryIssuesObj[entry].push({
                detail: '"Message" is required when using "Replacement"',
                order: 2,
                fieldName: 'display.replacement',
              })
          }
        })
      })
      return entryIssuesObj
    }
    default:
      return null
  }
}

export const sectionIssuesTransformer = (updatedSuggestions, section) => {
  const { section_suggestions, entries } = updatedSuggestions[section]
  const sectionIssuesObj = {
    section_suggestions: transformSectionIssues(
      section_suggestions,
      section,
      Object.keys(entries).length
    ),
    entries: transformEntryIssues(entries, section),
  }
  return sectionIssuesObj
}

export const issuesTransformer = (suggestions) => {
  const issuesObj = {}
  Object.keys(suggestions).forEach((section) => {
    issuesObj[section] = sectionIssuesTransformer(suggestions, section)
  })
  return issuesObj
}
