import React from 'react'
import { Button, Icon, Modal } from 'semantic-ui-react'
import './modal.css'

export const FormModal = ({
  title,
  description,
  submitButtonLabel,
  submitButtonColor = 'var(--primary)',
  closeButtonLabel,
  disableSubmitButton = false,
  onClose,
  onSave,
  children,
  show,
  modalClassName = '',
  size = 'large',
  isLoading = false,
  ...otherProps
}) => {
  const handleSave = async () => {
    await onSave()
  }
  return (
    <Modal
      size={size}
      open={Boolean(show)}
      data-testid="modal-form-page"
      onClose={onClose}
      className={modalClassName}
      {...otherProps}
    >
      <Modal.Header className="form-modal__header-container">
        {title}
        <Button
          icon
          onClick={onClose}
          data-testid="form-modal-close-button"
          className="basic-modal__close-button"
        >
          <Icon name="close" />
        </Button>
      </Modal.Header>
      <Modal.Content>
        {description && <p>{description}</p>}
        {children}
      </Modal.Content>
      <Modal.Actions>
        <Button
          data-testid="close_modal"
          className="form-modal__cancel-button"
          onClick={() => onClose(false)}
        >
          {closeButtonLabel}
        </Button>
        <Button
          data-testid="save_modal"
          primary
          loading={isLoading}
          disabled={isLoading || disableSubmitButton}
          onClick={handleSave}
          style={{ backgroundColor: submitButtonColor }}
        >
          {submitButtonLabel}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
