import { debounce } from 'lodash'

import fieldSuggestionCheck from './suggestionHelpers'

const debouncedFieldSuggestionCheck = debounce(fieldSuggestionCheck, 1000)

const formsWhitelist = [
  'static',
  'summary',
  'checklist',
  'postcall',
  'notifications',
  'categoryEntry',
]

export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (action.type === '@@redux-form/CHANGE' && formsWhitelist.includes(action.meta.form)) {
      debouncedFieldSuggestionCheck({
        dispatch,
        getState,
        field: action.meta.field,
        userInput: action.payload,
        formName: action.meta.form,
      })
    }
    if (action.type === '@@redux-form/DESTROY') {
      debouncedFieldSuggestionCheck.cancel()
    }
    return next(action)
  }
