// Action Types
import moment from 'moment'

export const SET_ALERT_SNOOZE = 'realtime/setAlertSnooze'
export const LOAD_ALERTS = 'realtime/loadAlerts'
export const DISMISS_ALERT = 'realtime/dismissAlert'
export const START_LISTENING_TO_CALL = 'realtime/startListeningToCall'
export const STOP_LISTENING_TO_CALL = 'realtime/stopListeningToCall'
export const LOAD_ALERT_TRANSCRIPTION = 'realtime/loadAlertTranscription'
export const UPDATE_ALERT_TRANSCRIPTION = 'realtime/updateAlertTranscription'
export const SET_SCROLL_TO_ALERT_TIME = 'realtime/setScrollToAlertTime'

export const setScrollToAlertTime = (payload) => ({ type: SET_SCROLL_TO_ALERT_TIME, payload })

// Initial State
export const initialState = {
  alerts: {},
  snooze: null,
  listeningToUsername: null,
  isLoading: false,
  transcription: [],
  startTime: null,
  scrollToAlertTime: null,
}

// Reducer
export default function realtimeUserAlerts(state = initialState, action) {
  switch (action.type) {
    case SET_ALERT_SNOOZE:
      return { ...state, snooze: action.snooze }
    case LOAD_ALERTS:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          [action.alert.username]: {
            callId: action.alert.call_id,
            agentId: action.alert.user_id,
            alertId: action.alert.alert_id,
            alertConfigId: action.alert.alert_config_id,
            managerId: action.alert.manager_id,
            agentUsername: action.alert.username,
            processingUrl: action.alert.processing_url,
            managerUsername: action.alert.manager_username,
            managerName: `${action.alert.manager_first_name} ${action.alert.manager_last_name}`,
            agentName: `${action.alert.user_first_name} ${action.alert.user_last_name}`,
            latestAlertIsoTime: moment().toISOString(),
            alerts: [
              ...(state.alerts[action.alert.username]?.alerts || []),
              // TODO: We should remove this when we are getting timestamp from backend
              { ...action.alert, timeStamp: moment().format('LT') },
            ],
          },
        },
      }
    case DISMISS_ALERT:
      return { ...state, alerts: action.alerts }
    case START_LISTENING_TO_CALL:
      return { ...state, listeningToUsername: action.agentUsername }
    case STOP_LISTENING_TO_CALL:
      return { ...state, listeningToUsername: null }
    case LOAD_ALERT_TRANSCRIPTION:
      // eslint-disable-next-line no-case-declarations
      const formattedTranscript = action.transcript.reduce((accum, current) => {
        const last = accum[accum.length - 1]
        if (last && last.side === current.side) {
          last.text = `${last.text} ${current.text}`
          last.endTime = current.time
          // eslint-disable-next-line no-param-reassign
          accum[accum.length - 1] = last
        } else {
          accum.push({ ...current, endTime: current.time })
        }
        return accum
      }, [])

      return { ...state, transcription: formattedTranscript, startTime: action.startTime }
    case UPDATE_ALERT_TRANSCRIPTION:
      // eslint-disable-next-line no-case-declarations
      const latestTranscript = state.transcription[state.transcription.length - 1]
      if (latestTranscript && latestTranscript?.side === action.transcription.side) {
        const updatedTranscription = {
          ...latestTranscript,
          text: `${latestTranscript.text} ${action.transcription.text}`,
          endTime: action.transcription.time,
        }

        return {
          ...state,
          transcription: [...state.transcription.slice(0, -1), updatedTranscription],
        }
      }

      return {
        ...state,
        transcription: [
          ...state.transcription,
          { ...action.transcription, endTime: action.transcription.time },
        ],
      }
    case SET_SCROLL_TO_ALERT_TIME:
      return { ...state, scrollToAlertTime: action.payload }
    default:
      return state
  }
}
