import React from 'react'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

export const Toasts = () => {
  return (
    <>
      <ToastContainer
        enableMultiContainer
        containerId="right"
        position="top-right"
        autoClose={4000}
        hideProgressBar
        newestOnTop
        draggable
        closeOnClick={false}
      />
      <ToastContainer
        enableMultiContainer
        containerId="middle"
        position="top-center"
        autoClose={4000}
        hideProgressBar
        newestOnTop
        draggable
        closeOnClick={false}
      />
      <ToastContainer
        enableMultiContainer
        containerId="bottom"
        position="bottom-center"
        autoClose={4000}
        hideProgressBar
        newestOnTop
        draggable
        closeOnClick={false}
      />
    </>
  )
}
