import moment from 'moment'

// The actual JSONB in the database
export const accessors = {
  CHECKLIST: 'checklist',
  DYNAMIC_PROMPT: 'deck',
  NOTIFICATION: 'notifications',
  POSTCALL: 'classified_postcall',
  STICKY_NOTE: 'static',
}

// The URLs for each module
export const sections = {
  CHECKLIST: 'checklist',
  DYNAMIC_PROMPT: 'dynamic-prompts',
  NOTIFICATION: 'notifications',
  POSTCALL: 'postcall',
  STICKY_NOTE: 'sticky-note',
  BEACON: 'beacon',
}

export const initialPlaybookBody = {
  [accessors.CHECKLIST]: { order: [], entries: {} },
  [accessors.DYNAMIC_PROMPT]: { order: [], entries: {} },
  [accessors.NOTIFICATION]: { entries: {} },
  [accessors.POSTCALL]: { order: [], entries: {} },
  [accessors.STICKY_NOTE]: { order: [], entries: {} },
  postcall: { entries: {} },
  summary: { entries: {} },
}

export const initialPlaybookWarningsBody = {
  [accessors.CHECKLIST]: {},
  [accessors.DYNAMIC_PROMPT]: {},
  [accessors.NOTIFICATION]: {},
  [accessors.POSTCALL]: {},
  [accessors.STICKY_NOTE]: {},
}

// Whether the module is ordered
export const orderedEntriesAccessorMap = {
  [accessors.CHECKLIST]: true,
  [accessors.DYNAMIC_PROMPT]: true,
  [accessors.NOTIFICATION]: false,
  [accessors.POSTCALL]: true,
  [accessors.STICKY_NOTE]: true,
}

export const playbookTitlesSectionMap = {
  [sections.CHECKLIST]: 'Checklist',
  [sections.DYNAMIC_PROMPT]: 'Dynamic Prompts',
  [sections.NOTIFICATION]: 'Notifications',
  [sections.POSTCALL]: 'Post Call',
  [sections.STICKY_NOTE]: 'Sticky Note',
  [sections.BEACON]: 'Beacon',
}

export const accessorSectionMap = {
  [sections.CHECKLIST]: accessors.CHECKLIST,
  [sections.DYNAMIC_PROMPT]: accessors.DYNAMIC_PROMPT,
  [sections.STICKY_NOTE]: accessors.STICKY_NOTE,
  [sections.NOTIFICATION]: accessors.NOTIFICATION,
  [sections.POSTCALL]: accessors.POSTCALL,
}

export const notificationTriggerTypes = [
  { label: 'Talk Speed', value: 'words_per_minute' },
  { label: 'Dead Air', value: 'dead_air' },
]

export const notificationTriggerTypesDeprecated = [
  ...notificationTriggerTypes,
  { label: 'Keywords', value: 'keywords', isOptionDisabled: true },
  { label: 'Keywords', value: 'verbatim', isOptionDisabled: true },
]

export const categoryTriggerTypes = [
  { label: 'AI Library', value: 'transcription_classifier' },
  { label: 'Keywords', value: 'keywords' },
  { label: 'Talk Ratio', value: 'talk_ratio' },
  { label: 'Phrase Matching', value: 'verbatim' },
]

export const dynamicPromptTriggerTypes = [{ label: 'None', value: 'none' }, ...categoryTriggerTypes]

export const triggerTimeUnits = [
  { label: 'Seconds', value: 'sec' },
  { label: 'Minutes', value: 'min' },
]

export const sides = [
  { label: 'Me', value: 'me' },
  { label: 'Them', value: 'them' },
  { label: 'Both', value: 'both' },
]

export const getPlaybookVersionOptions = (versions) => {
  return versions.map((version) => ({
    value: version.id,
    label: `${version.name} \u2013 ${moment(version.created).format('l LT')}`,
  }))
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const getUpdatedSourceAndDestination = ({
  entries,
  source,
  destination,
  sourceCategoryId,
  destinationCategoryId,
}) => {
  const sourceOrder = entries[sourceCategoryId].order
  const sourceEntries = entries[sourceCategoryId].entries
  const destinationOrder = entries[destinationCategoryId].order
  const destinationEntires = entries[destinationCategoryId].entries

  const [removed] = sourceOrder.splice(source.index, 1)
  destinationOrder.splice(destination.index, 0, removed)

  destinationEntires[removed] = sourceEntries[removed]
  delete sourceEntries[removed]

  const updatedSourceAndDestination = {
    source: { id: sourceCategoryId, order: sourceOrder, entries: sourceEntries },
    destination: {
      id: destinationCategoryId,
      order: destinationOrder,
      entries: destinationEntires,
    },
  }

  return updatedSourceAndDestination
}

export const getPlaybookButtonDisabledStatus = (
  readyToRestore,
  readyToSaveDraft,
  readyToPublish,
  loading
) => {
  if (loading) {
    return true
  }

  if (!readyToSaveDraft && !readyToPublish && !readyToRestore) {
    return true
  }

  return false
}

export const getPlaybookButtonText = (readyToRestore, readyToPublish) => {
  if (readyToRestore) {
    return 'Restore Playbook'
  }

  if (readyToPublish) {
    return 'Publish Draft'
  }

  return 'Save Draft'
}
