export const getSchema = ({ typeOptions, organizationOptions }) => [
  {
    label: 'Name',
    name: 'name',
    type: 'text',
    required: true,
  },
  {
    label: 'Integration Type',
    name: 'type',
    type: 'select',
    options: typeOptions,
    required: true,
  },
  {
    label: 'Organization',
    name: 'organization_id',
    type: 'select',
    options: organizationOptions,
    required: true,
  },
]
const createStartStopSelect = (defaultValue, enabled) => {
  return [
    {
      label: 'Start Stop Method',
      name: 'remote_id_method',
      type: 'select',
      options: [
        { value: 'by_email', label: 'By Email' },
        { value: 'by_voip_user_id', label: 'By Voip User ID' },
      ],
      defaultValue,
      isDisabled: !enabled,
      required: true,
    },
  ]
}
const baseConnector = [
  {
    label: 'Integration Type',
    name: 'display_type', // this value is ignored in the backend but is used to make it more readable for FE
    type: 'text',
    required: true,
    disabled: true,
  },
  {
    label: 'Organization',
    name: 'organization_id',
    type: 'text',
    required: true,
    disabled: true,
  },
  {
    label: 'Token',
    name: 'token',
    type: 'text',
    required: false,
    disabled: true,
    defaultValue: 'None',
  },
]
const ringcentralSchema = [
  ...baseConnector,
  ...createStartStopSelect('by_email', false),
  {
    label: 'JWT',
    name: 'jwt_token',
    type: 'text',
  },
  {
    label: 'VOIP Call ID',
    name: 'voip_call_id',
    type: 'select',
    options: [
      { value: 'id', label: 'ID' },
      { value: 'telephonySessionId', label: 'Telephony Session ID' },
      { value: 'sessionId', label: 'Session ID' },
      { value: 'callerId', label: 'Legacy Phn Num (AIN)' },
      { value: '', label: 'None' },
    ],
    defaultValue: 'telephonySessionId',
  },
  {
    label: 'VOIP Campaign Name',
    name: 'voip_campaign_name',
    type: 'select',
    options: [
      { value: 'callDirection', label: 'Call Direction' },
      { value: 'repNum', label: 'Internal Phone Number.' },
      { value: 'callD&repNum', label: 'Call Dir. + Rep Num.' },
      { value: '', label: 'None' },
    ],
    defaultValue: 'callDirection',
  },
]
const genesysSchema = [
  ...baseConnector,
  ...createStartStopSelect('by_email', false),
  {
    label: 'Audio API Key',
    name: 'audio_api_key',
    type: 'text',
    disabled: true,
    required: false,
    nullable: true,
  },
  {
    label: 'Client Id',
    name: 'client_id',
    type: 'text',
    required: true,
  },
  {
    label: 'Client Secret',
    name: 'client_secret',
    type: 'text',
    required: true,
  },
  {
    label: 'Authentication Server',
    name: 'auth_server',
    type: 'text',
    required: true,
  },
  {
    label: 'API Server',
    name: 'api_server',
    type: 'text',
    required: true,
  },
  {
    label: 'VOIP Call ID',
    name: 'voip_call_id',
    type: 'select',
    options: [
      { value: 'uuid', label: 'UUID' },
      { value: '', label: 'None' },
    ],
    defaultValue: 'uuid',
  },

  {
    label: 'VOIP Campaign Name',
    name: 'voip_campaign_name',
    type: 'select',
    options: [
      { value: 'queue.id', label: 'Queue' },
      { value: 'direction', label: 'Direction' },
      { value: 'queue.id+direction', label: 'Queue + Direction' },
      { value: '', label: 'None' },
    ],
    defaultValue: 'queue.id',
  },
  {
    label: 'Audio Connector',
    name: 'audio_enabled',
    type: 'checkbox',
    defaultValue: false,
  },
  {
    label: 'Auto Provision Users',
    name: 'auto_provision',
    type: 'checkbox',
    defaultValue: false,
  },
]

const five9Schema = [
  ...baseConnector,
  ...createStartStopSelect('by_email', true),
  {
    label: 'VOIP User ID Parameter',
    name: 'remote_id_source',
    type: 'select',
    options: [
      { value: 'Agent.id', label: 'Agent ID' },
      { value: 'Agent.user_name', label: 'Agent Username' },
    ],
    defaultValue: 'Agent.id',
    dependencies: [
      {
        key: 'remote_id_method',
        operator: '===',
        value: 'by_voip_user_id',
      },
    ],
  },
  {
    label: 'Five9 Admin Username',
    name: 'username',
    type: 'text',
    required: true,
  },
  {
    label: 'Five9 Admin Password',
    name: 'password',
    type: 'password',
    required: true,
  },
  {
    label: 'VOIP Call ID',
    name: 'voip_call_id',
    type: 'select',
    options: [
      { value: 'Call.session_id', label: 'Session ID' },
      { value: 'Call.call_id', label: 'Call ID' },
      { value: '', label: 'None' },
    ],
    defaultValue: 'Call.call_id',
  },
  {
    label: 'VOIP Campaign Name',
    name: 'voip_campaign_name',
    type: 'select',
    options: [
      { value: 'Call.campaign_name', label: 'Call Campaign Name' },
      { value: 'Call.skill_name', label: 'Call Skill Name' },
      { value: '', label: 'None' },
    ],
    defaultValue: 'Call.campaign_name',
  },
]

const slackSchema = [
  ...baseConnector,
  ...createStartStopSelect('by_email', false),
  {
    label: 'Channel',
    name: 'channel',
    type: 'text',
    required: true,
  },
]

const eightByEightSchema = [
  ...baseConnector,
  ...createStartStopSelect('by_email', false),
  {
    label: 'Server (ex: vcc-pp33)',
    name: 'dns',
    type: 'text',
    required: true,
  },
  {
    label: '8x8 Admin Username (Tenant Id)',
    name: 'username',
    type: 'text',
    required: true,
  },
  {
    label: '8x8 Data Request Token',
    name: 'password',
    type: 'password',
    required: true,
  },
  {
    label: 'VOIP Call ID',
    name: 'voip_call_id',
    type: 'select',
    options: [
      { value: 'interactionGUID', label: 'Interaction GUID' },
      { value: '', label: 'None' },
    ],
    defaultValue: 'interactionGUID',
  },

  {
    label: 'VOIP Campaign Name',
    name: 'voip_campaign_name',
    type: 'select',
    options: [
      { value: 'tenantSkillName', label: 'Tenant Skill Name' },
      { value: 'que', label: 'Queue' },
      { value: 'queueDirection', label: 'Queue Direction' },
      { value: 'tenantName', label: 'Tenant Name' },
      { value: '', label: 'None' },
    ],
    defaultValue: 'tenantSkillName',
  },
]

const vonageEnterpriseSchema = [
  ...baseConnector,
  ...createStartStopSelect('by_email', false),
  {
    label: 'API Key',
    name: 'api_key',
    type: 'text',
    required: true,
  },
  {
    label: 'API Secret',
    name: 'api_secret',
    type: 'password',
    required: true,
  },
  {
    label: 'Application ID',
    name: 'application_id',
    type: 'text',
    required: true,
  },
  {
    label: 'VOIP Call ID',
    name: 'voip_call_id',
    type: 'select',
    options: [
      { value: 'uuid', label: 'UUID' },
      { value: '', label: 'None' },
    ],
    defaultValue: 'uuid',
  },
  {
    label: 'VOIP Campaign Name',
    name: 'voip_campaign_name',
    type: 'select',
    options: [
      { value: 'direction', label: 'Direction' },
      { value: '', label: 'None' },
    ],
    defaultValue: 'direction',
  },
]

const amazonConnectSchema = [
  ...baseConnector,
  ...createStartStopSelect('by_voip_user_id', false),
  {
    label: 'API Key',
    name: 'api_key',
    type: 'text',
    required: true,
  },
  {
    label: 'API Secret',
    name: 'api_secret',
    type: 'password',
    required: true,
  },
  {
    label: 'Region',
    name: 'region',
    type: 'select',
    options: [
      { value: 'us-east-1', label: 'us-east-1' },
      { value: 'us-east-2', label: 'us-east-2' },
      { value: 'us-west-1', label: 'us-west-1' },
      { value: 'us-west-2', label: 'us-west-2' },
    ],
    defaultValue: 'us-east-1',
    required: true,
  },
  {
    label: 'Role Arn',
    name: 'role_arn',
    type: 'text',
    required: true,
  },
]

const zoomSchema = [
  ...baseConnector,
  ...createStartStopSelect('by_email', false),
  {
    label: 'Access Token',
    name: 'access_token',
    type: 'text',
    required: true,
  },
  {
    label: 'Refresh Token',
    name: 'refresh_token',
    type: 'text',
    required: true,
  },
  {
    label: 'account_id',
    name: 'account_id',
    type: 'text',
    required: true,
  },
  {
    label: 'VOIP Call ID',
    name: 'voip_call_id',
    type: 'select',
    options: [
      { value: 'call_id', label: 'Call ID' },
      { value: '', label: 'None' },
    ],
    defaultValue: '',
  },
  {
    label: 'VOIP Campaign Name',
    name: 'voip_campaign_name',
    type: 'select',
    options: [
      { value: 'direction', label: 'Direction' },
      { value: '', label: 'None' },
    ],
    defaultValue: '',
  },
]

const closeSchema = [
  ...baseConnector,
  ...createStartStopSelect('by_email', false),
  {
    label: 'API Key',
    name: 'api_key',
    type: 'text',
    required: true,
  },
]

const aircallSchema = [...baseConnector, ...createStartStopSelect('by_email', false)]

const tldSchema = [
  ...baseConnector,
  ...createStartStopSelect('by_voip_user_id', false),
  {
    label: 'VOIP Campaign Name',
    name: 'voip_campaign_name',
    type: 'select',
    options: [
      { value: 'list_name', label: 'List Name' },
      { value: 'list_id', label: 'List ID' },
      { value: 'user_group', label: 'User Group' },
      { value: 'group', label: 'Group' },
      { value: 'direction', label: 'Direction' },
      { value: 'type', label: 'Type' },
      { value: '', label: 'None' },
    ],
    defaultValue: 'direction',
  },
  {
    label: 'VOIP Call ID',
    name: 'voip_call_id',
    type: 'select',
    options: [
      { value: 'call_id', label: 'Call ID' },
      { value: '', label: 'None' },
    ],
    defaultValue: 'call_id',
    disabled: true,
  },
]

const trackdriveSchema = [...baseConnector, ...createStartStopSelect('by_email', false)]

const thrioSchema = [
  ...baseConnector,
  ...createStartStopSelect('by_email', false),
  {
    label: 'VOIP Campaign Name',
    name: 'voip_campaign_name',
    type: 'select',
    options: [
      { value: 'direction', label: 'Direction' },
      { value: 'campaignName', label: 'Campaign Name' },
      { value: '', label: 'None' },
    ],
    defaultValue: 'direction',
  },
  {
    label: 'VOIP Call ID',
    name: 'voip_call_id',
    type: 'select',
    options: [
      { value: 'workitemId', label: 'Work Item ID' },
      { value: '', label: 'None' },
    ],
    defaultValue: '',
  },
]

const niceSchema = [
  ...baseConnector,
  ...createStartStopSelect('by_voip_user_id', false),
  {
    label: 'Access Key ID',
    name: 'access_key_id',
    type: 'text',
    required: true,
  },
  {
    label: 'Access Key ID Secret',
    name: 'access_key_secret',
    type: 'password',
    required: true,
  },
  {
    label: 'API Server',
    name: 'api_server',
    type: 'text',
    required: true,
  },
  {
    label: 'Audio API Key',
    name: 'audio_api_key',
    type: 'text',
    required: false,
    nullable: true,
  },
  {
    label: 'Audio Connector',
    name: 'audio_enabled',
    type: 'checkbox',
    defaultValue: true,
  },
]

const vonageVbeSchema = [
  ...baseConnector,
  ...createStartStopSelect('by_voip_user_id', false),
  {
    label: 'Admin Username',
    name: 'admin_username',
    type: 'text',
    required: true,
  },
  {
    label: 'Admin Password',
    name: 'admin_password',
    type: 'password',
    required: true,
  },
  {
    label: 'Org name in Vonage VBE',
    name: 'vonage_vbe_org_name',
    type: 'text',
    required: true,
  },
  {
    label: 'Events API URL',
    name: 'dns_events_url',
    type: 'text',
    required: true,
  },
]

const ringcentralEngageSchema = [
  ...baseConnector,
  // ...createStartStopSelect('by_voip_user_id', false),
  {
    label: 'JWT Token',
    name: 'jwt_token',
    type: 'text',
    required: true,
  },
  {
    label: 'Product Id',
    name: 'product_id',
    type: 'text',
    required: true,
  },
  {
    label: 'Main Account Id',
    name: 'main_account_id',
    type: 'text',
    required: true,
  },
  {
    label: 'Sub Account Id',
    name: 'sub_account_id',
    type: 'text',
    required: true,
  },
  {
    label: 'RC Account Id',
    name: 'rc_account_id',
    type: 'text',
    required: true,
  },
  {
    label: 'Audio API Key',
    name: 'audio_api_key',
    type: 'text',
    required: true,
  },
]

const configTypeToSchemaMap = {
  ringcentral: ringcentralSchema,
  ringcentral_engage: ringcentralEngageSchema,
  genesys: genesysSchema,
  five9: five9Schema,
  '8x8': eightByEightSchema,
  slack: slackSchema,
  vonage_enterprise: vonageEnterpriseSchema,
  amazon_connect: amazonConnectSchema,
  zoom: zoomSchema,
  close: closeSchema,
  aircall: aircallSchema,
  tld: tldSchema,
  trackdrive: trackdriveSchema,
  thrio: thrioSchema,
  nice: niceSchema,
  vonage_vbe: vonageVbeSchema,
}

export const getEditSchema = (type) => {
  return configTypeToSchemaMap[type]
}

export const buildDefaults = (integration, schema) => {
  const updatedIntegration = { ...integration }
  schema?.forEach((fieldOptions) => {
    if (fieldOptions.defaultValue && integration[fieldOptions.name] == null) {
      updatedIntegration[fieldOptions.name] = fieldOptions.defaultValue
    }
    if (fieldOptions.name === 'organization_id') {
      if (updatedIntegration[fieldOptions.name] >= 90000) {
        fieldOptions.disabled = false
      }
    }
  })

  return updatedIntegration
}
