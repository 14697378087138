import { isEmpty } from 'lodash'

const contextifyInlinePhrase = (phrase) => phrase.trim().replace(/-/g, ' ').replace(/  +/g, ' ')

const lineBreakOpenTag = new RegExp('<br>', 'g')

/**
 * @param  {String} value - Comma seperated string of keywords/phrases that we will sanitize
 * @returns An array of keywords that has been regexed by allowedCharacters, duplicates
 * removed, and leading and trailing spaces trimmed
 */
export const parseKeywords = (value, exampleCharacters = false) => {
  // allows letters, apostrophes, periods, spaces, dashes
  // opening and closing square brackets, underscores, less than, greater than
  // escaping. allows question marks for examples ONLY
  const allowedCharacters = exampleCharacters
    ? RegExp("^[A-Za-z\\s\\[\\]\\._'<>-?]+$")
    : RegExp("^[A-Za-z\\s\\[\\]\\._'<>-]+$")

  if (typeof value === 'undefined') {
    return []
  }

  if (typeof value === 'string') {
    const sanitizedKeywords = new Set(
      value
        .split(',')
        .filter((keyword) => allowedCharacters.test(keyword.trim()))
        .map((keyword) => keyword.trim())
    )
    return [...sanitizedKeywords]
  }
  const sanitizedKeywords = new Set(
    value
      .filter((keyword) => allowedCharacters.test(keyword.trim()))
      .map((keyword) => keyword.trim())
  )
  return [...sanitizedKeywords]
}
/**
 * @param  {Object} trigger - config trigger object
 * @description removes any not allowed properties from the trigger object, depending on the trigger
 * type selected.
 * @returns Schema valid trigger object, or -1 if the trigger selected was 'none' for bookmarks
 */
export const sanitizeTrigger = (trigger) => {
  // this function prevents a bug where a state can persist trigger type
  const allowedProps = {
    transcription_classifier: [
      'type',
      'side',
      'krid',
      'max_triggers',
      'seconds_sampled',
      'minimum_occurrences',
      'after',
      'before',
    ],
    keywords: [
      'type',
      'side',
      'phrases',
      'max_triggers',
      'seconds_sampled',
      'minimum_occurrences',
      'after',
      'before',
    ],
    words_per_minute: [
      'type',
      'side',
      'word_count',
      'max_triggers',
      'trigger_cooldown',
      'seconds_sampled',
      'minimum_occurrences',
      'after',
      'before',
    ],
    talk_ratio: [
      'type',
      'side',
      'ratio',
      'max_triggers',
      'trigger_cooldown',
      'seconds_sampled',
      'minimum_occurrences',
      'after',
      'before',
    ],
    dead_air: ['type', 'threshold_time', 'max_triggers', 'time_unit'],
    verbatim: [
      'type',
      'side',
      'max_triggers',
      'minimum_occurences',
      'after',
      'before',
      'additional_info',
      'required_before',
      'occurrence_required',
    ],
  }
  const numberProps = [
    'max_triggers',
    'seconds_sampled',
    'minimum_occurrences',
    'word_count',
    'trigger_cooldown',
    'required_before',
    'ratio',
    'after',
    'before',
    'threshold_time',
  ]
  const { type } = trigger
  if (type === 'none') return -1
  const newTrigger = {}
  Object.keys(trigger).forEach((prop) => {
    // we have to null check because redux forms doesn't remove the property if it's null
    if (allowedProps[type].includes(prop) && trigger[prop]) {
      newTrigger[prop] = numberProps.includes(prop) ? Number(trigger[prop]) : trigger[prop]
    }
  })
  if (type === 'keywords') newTrigger.phrases = parseKeywords(newTrigger.phrases)
  // 0 and 3660 are the defaults
  if (newTrigger.after === 0) delete newTrigger.after
  if (newTrigger.before === 3660) delete newTrigger.before
  return newTrigger
}

const stripInlinePhrases = (display) => {
  const parser = new DOMParser()
  const displayDoc = parser.parseFromString(display, 'text/html')

  if (displayDoc.querySelectorAll('[data-type="inline-verbatim-container"]')) {
    const { children } = displayDoc.body
    const sanitizedChildren = Array.from(children).map((element) => {
      if (element.getAttribute('data-type') === 'inline-verbatim-container') {
        const { children: inlineVerbatims } = element
        Array.from(inlineVerbatims).forEach((inlineVerbatim) => {
          // we intentionally mutate here
          // eslint-disable-next-line no-param-reassign
          inlineVerbatim.innerText = contextifyInlinePhrase(inlineVerbatim.innerText)
        })
      }
      return element.outerHTML
    })
    return sanitizedChildren.join('')
  }

  return display
}

const mapSelfClosingLineBreak = (display) => {
  return display.replace(lineBreakOpenTag, '<br />')
}

const handleHtml = (display) => {
  const displayInlinePhrasesStripped = stripInlinePhrases(display)
  return mapSelfClosingLineBreak(displayInlinePhrasesStripped)
}

/**
 * @param  {Object} display - config display object
 * @description removes any not allowed properties from the display object, depending on the display
 * type selected.
 * @returns Schema valid display object, or {} if display passed was undefined
 */
export const sanitizeDisplay = (display) => {
  // this function prevents a bug where a state can persist display type
  // there isn't a good way for redux forms to handle switching between an array of displays and
  // a single object. so we serialize it to store it in redux then deserialize to display/edit it
  // if we ever allow a different display type in the multi-display we will
  // need a more robust way of handling this
  const hasDecklist = !isEmpty(display.decklist)

  if (hasDecklist) {
    const { order, entries } = display.decklist
    // this filters out people pressing "add item" and not adding anything
    const filteredOrder = order.filter(
      (decklistId) => !isEmpty(entries[decklistId]) && !isEmpty(entries[decklistId].name.trim())
    )

    const newEntries = {}

    filteredOrder.forEach((decklistId) => {
      newEntries[decklistId] = { name: contextifyInlinePhrase(entries[decklistId].name) }
    })

    display.decklist = { order: filteredOrder, entries: newEntries }
  }

  const emptyHtml = isEmpty(display.html) || display.html === '<p></p>'
  const isClosingLoop =
    !isEmpty(display.header) ||
    (hasDecklist && !isEmpty(display.decklist.order)) ||
    !isEmpty(display.footer)
  const isMulti = !emptyHtml && isClosingLoop

  const allowedProps = {
    html: ['type', 'html'],
    language_substitution: ['type', 'replacement', 'reason'],
    closing_loop: ['type', 'header', 'decklist', 'footer'],
  }

  const sanitize = (_display) => {
    const newDisplay = {}
    if (!_display) return newDisplay
    Object.keys(_display).forEach((prop) => {
      if (allowedProps[_display.type].includes(prop)) {
        newDisplay[prop] = _display[prop]
      }
    })

    if (newDisplay.type === 'language_substitution' && newDisplay.replacement === '')
      delete newDisplay.replacement
    if (newDisplay.type === 'language_substitution' && newDisplay.reason === '')
      delete newDisplay.reason
    if (newDisplay.type === 'html') newDisplay.html = handleHtml(newDisplay.html)
    if (newDisplay.type === 'closing_loop') {
      if (newDisplay.header === '') delete newDisplay.header
      if (newDisplay.footer === '') delete newDisplay.footer
      if (newDisplay.decklist && newDisplay.decklist.order.length === 0) delete newDisplay.decklist
    }

    return newDisplay
  }

  if (isMulti) {
    // order doesn't matter because we just flatten these objects together
    return [sanitize({ ...display, type: 'html' }), sanitize({ ...display, type: 'closing_loop' })]
  }

  let { type } = display // this just sets type as undefined if neither of those two conditions is true
  if (!emptyHtml) {
    type = 'html'
  } else if (isClosingLoop) {
    type = 'closing_loop'
  }

  return sanitize({
    // this is stupid but because we flatten the array we can't trust type
    ...display,
    type,
  })
}
/**
 * @param {Object} entry - config entry object
 * @param {String} section - name of config section
 * @returns categoryEntry object with valid properties for a category entry classified_postcall
 * gets display deleted, and decks get trigger deleted if -1 ('none' was selected)
 */
export const sanitizeCategoryEntry = (entry, section) => {
  const newEntry = entry
  if (newEntry.trigger === -1) delete newEntry.trigger
  if (section === 'classified_postcall') delete newEntry.display
  delete newEntry.id

  return newEntry
}
/**
 * @param {Object} entry - config entry object
 * @returns Static entry object that will not have a 'name' property if one was not input
 */
export const sanitizeStatic = (entry) => {
  const newEntry = entry
  if (typeof newEntry.name !== 'string' || newEntry.name === '') delete newEntry.name
  return newEntry
}
/**
 * @param {Object} entry - config entry object
 * @returns Valid summary entry object that has additional properties for
 * required settings removed if required was not selected.
 */
export const sanitizeSummary = (entry) => {
  const newEntry = entry
  const { required } = entry
  if (typeof required === 'undefined' || required === false) {
    delete newEntry.duration_prerequisite
    return newEntry
  }
  return newEntry
}
