import React from 'react'
import { Table, Input } from 'semantic-ui-react'
import classNames from 'classnames'

import SortButton from '../../SortButton'

export const AdvancedTableHeader = ({
  actions,
  columns,
  orderBy,
  order,
  onRequestSort,
  updateSearch,
  smallActionColumn = false,
  stickyHeader = false,
  stickyAction = false,
  truncate = false,
  wrapColumnContent = true,
  searchFilters = {},
}) => {
  const createSortHandler = (accessor) => (event) => {
    onRequestSort(event, accessor)
  }
  return (
    <Table.Header>
      <Table.Row>
        {columns.map((headCell) => (
          <th
            key={headCell.accessor}
            data-testid={`th--${headCell.accessor}`}
            className={classNames({
              'sticky-header': stickyHeader,
              'sticky-column': headCell.sticky,
              'no-wrap': !wrapColumnContent,
            })}
            style={{
              borderBottom: `2px solid ${headCell.color ? headCell.color : 'var(--border-color)'}`,
            }}
          >
            <SortButton
              label={headCell.label}
              handleClick={createSortHandler(headCell.accessor)}
              dataType={headCell.accessor}
              sortBy={orderBy}
              asc={order === 'asc'}
              data-testid={`sort-button-${headCell.accessor}`}
              truncate={truncate}
            />
          </th>
        ))}
        {!!actions.length && (
          <th
            style={{ textAlign: 'center' }}
            className={classNames({
              'sticky-header': stickyHeader,
              'sticky-action': stickyAction,
              'action-column-min-width': !stickyAction && !smallActionColumn,
              'sticky-action-sm': stickyAction && smallActionColumn,
              'sticky-action-lg': stickyAction && !smallActionColumn,
              'no-wrap': !wrapColumnContent,
            })}
          >
            Actions
          </th>
        )}
      </Table.Row>
      {columns.some((column) => column.isSearchable) && (
        <Table.Row>
          {columns.map((headCell) => {
            if (headCell.isSearchable) {
              return (
                <th
                  key={headCell.accessor}
                  className={classNames({
                    'sticky-column': headCell.sticky,
                  })}
                >
                  <Input
                    data-testid={`${headCell.label}-search`}
                    type="text"
                    icon="search"
                    iconPosition="left"
                    style={{ width: '100%' }}
                    placeholder={`Search ${headCell.label}...`}
                    value={searchFilters[headCell.accessor] || ''}
                    onChange={(e) => {
                      updateSearch(headCell.accessor, e.target.value)
                    }}
                  />
                </th>
              )
            }

            return <th key={headCell.accessor} />
          })}
          {!!actions.length && (
            <th key="empty-cell" className={classNames({ 'sticky-action': stickyAction })} />
          )}
        </Table.Row>
      )}
    </Table.Header>
  )
}
