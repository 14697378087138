import { fetchingAPI, apiService } from '../../api'
import { setOrganizations } from './organizations.redux'

export const fetchOrganizations = () => async (dispatch) => {
  try {
    const organizations = await fetchingAPI(`${apiService.web}/api/organizations`, 'GET', dispatch)
    dispatch(setOrganizations(organizations))
  } catch (err) {
    console.error(err)
  }
}

export const createOrganization =
  ({ name, push }) =>
  async (dispatch) => {
    try {
      const organization = await fetchingAPI(
        `${apiService.web}/api/organizations`,
        'POST',
        dispatch,
        JSON.stringify({ name })
      )

      dispatch(fetchOrganizations())

      push(`/organizations/${organization.id}`)
    } catch (err) {
      // catching error
    }
  }
