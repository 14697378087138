import { loadAlertTranscription } from '../reducers/realtime/realtime.actions'
import { PCMPlayer } from '../utils/PCMPlayer'
import { fetchingAPI, apiService } from '../api'

let mePlayer = null
let themPlayer = null

const defaultPlayerSettings = {
  encoding: '16bitInt',
  channels: 1,
  sampleRate: 48000, // 44100 is default
  flushingTime: 1000,
}

export default (meSocket, themSocket) =>
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const { type, ...actionData } = action
    const { username, token } = getState().currentUser

    const processingProtocol = process.env.PROCESSING_WS_PROTOCOL || 'ws://'
    const mePlayerSettings = { ...defaultPlayerSettings }
    const themPlayerSettings = { ...defaultPlayerSettings }

    switch (type) {
      case 'realtime/startListeningToCall':
        fetchingAPI(
          `${apiService.web}/api/audio_info`,
          'POST',
          dispatch,
          JSON.stringify({
            call_id: actionData?.alertCallId,
            requestFor: actionData?.agentUsername,
            requestAs: actionData?.managerUsername,
            processing_url: actionData?.processingUrl,
          })
        )
          .then((res) => {
            mePlayerSettings.sampleRate = res?.me?.rate || mePlayerSettings.sampleRate
            themPlayerSettings.sampleRate = res?.them?.rate || themPlayerSettings.sampleRate

            mePlayer = new PCMPlayer(mePlayerSettings)
            themPlayer = new PCMPlayer(themPlayerSettings)

            dispatch(
              loadAlertTranscription({
                processingURL: actionData?.processingUrl,
                callID: actionData?.alertCallId,
              })
            )

            meSocket.connect({
              token,
              username,
              binaryType: 'arraybuffer',
              url: `${processingProtocol}${actionData?.processingUrl}/ws/listen/${actionData?.alertCallId}?side=me`,
            })

            themSocket.connect({
              token,
              username,
              binaryType: 'arraybuffer',
              url: `${processingProtocol}${actionData?.processingUrl}/ws/listen/${actionData?.alertCallId}?side=them`,
            })

            meSocket.on('open', () => {
              console.info('Me side listening socket open!')
              const alertId = actionData?.alertId
              fetchingAPI(`${apiService.rtc}/alert/${alertId}`, 'PATCH', dispatch).catch((error) =>
                console.error(error)
              )
            })

            themSocket.on('open', () => {
              console.info('Them side listening socket open!')
            })

            meSocket.on('message', (event) => {
              mePlayer.feed(new Int16Array(event.data))
            })

            themSocket.on('message', (event) => {
              themPlayer.feed(new Int16Array(event.data))
            })

            meSocket.on('close', () => {
              console.info('Me side listening socket closed!')
            })

            themSocket.on('close', () => {
              console.info('Them side listening socket closed!')
            })
          })
          .catch((e) => console.error('ERROR ATTEMPTING TO LISTEN', e))

        break
      case 'realtime/stopListeningToCall':
        if (meSocket) {
          meSocket.disconnect()
        }

        if (themSocket) {
          themSocket.disconnect()
        }

        if (mePlayer) {
          mePlayer.destroy()
          mePlayer = null
        }

        if (themPlayer) {
          themPlayer.destroy()
          themPlayer = null
        }
        break
      default:
        break
    }

    return next(action)
  }
