import { handleResponse } from './handleResponse'
import store from '../store'
import { history } from '../entry'
import { logoutUser } from '../reducers/auth/currentUser.redux'

/**
 * fetchingAPI is a wrapper for Balto APIs.
 *
 * It grabs the user data from local storage and passes it into the headers, then handles the
 * response in a separate function.
 *
 * We should not be pulling from local storage, but rather from Redux or API interceptors.
 * This could be accomplished with axios.
 *
 * @param {string} url The full API endpoint URL
 * @param {string} method One of GET, POST, PUT, PATCH, DELETE
 * @param {Function} dispatch The Redux dispatch method
 * @param {Object} body Optional body for POST, PUT, PATCH requests
 * @param {Function} callback Optional callback function
 * @returns {Promise} Returns a promise that either resolves or rejects based on the response code
 */
export const fetchingAPI = (url, method, dispatch, body, callback, details = {}) => {
  const state = store.getState()
  const { username, organizationid, token } = state.currentUser

  // If no token exists, don't attempt to hit the endpoint because it will always fail
  if (!token) {
    console.info('Token not found. Logging out user.')
    // Log user out of Redux
    dispatch(logoutUser())

    // Clear user from local storage
    localStorage.removeItem('User')
    localStorage.removeItem('balto_org_id')

    // Redirect to login
    history.push('/login')
  }

  return fetch(url, {
    method,
    body,
    headers: {
      'Content-Type': 'application/json',
      'auth-type': 'web',
      username,
      organizationid,
      token,
    },
  }).then((response) => handleResponse(response, dispatch, callback, details))
}
