import queryString from 'query-string'
import { batch } from 'react-redux'
import { toast } from 'react-toastify'

import { fetchingAPI, apiService } from '../../api'
import { fetchOrganizationData } from './organization.actions'
import {
  setActivationCode,
  setLoading,
  setActivationCodesForOrganization,
  setUsers,
} from './organizationUsers.redux'
import { closeModal } from '../ui/ui.redux'
import { updateUserPermissions } from '../auth/currentUser.redux'
import { buildUsersWithAppStatus } from '../../utils/helpers'
import { setRouteError } from '../errors/routeErrors.redux'

export const generateActivationCodeForUser = (userId, organizationId) => async (dispatch) => {
  dispatch(setLoading({ activationCodeModal: true }))

  try {
    const response = await fetchingAPI(
      `${apiService.auth}/activation_codes?user_id=${userId}`,
      'POST',
      dispatch
    )

    dispatch(setActivationCode(response[0].code))
    dispatch(fetchOrganizationData({ organizationId }))
  } catch (err) {
    dispatch(setRouteError('Failed to generate activation code for user'))
  } finally {
    dispatch(setLoading({ activationCodeModal: false }))
  }
}

export const revokeActivationCodeForUser = (user) => async (dispatch) => {
  dispatch(setLoading({ activationCodeModal: true }))

  try {
    await fetchingAPI(`${apiService.auth}/activation_codes?user_id=${user.id}`, 'DELETE', dispatch)

    dispatch(setActivationCode(''))
    dispatch(closeModal())
    dispatch(fetchOrganizationData({ organizationId: user.organization_id }))

    toast.success(`Access to Agent App has been revoked for ${user.full_name}`, {
      containerId: 'right',
    })
  } catch (err) {
    dispatch(setRouteError('Failed to revoke activation code for user'))
  } finally {
    dispatch(setLoading({ activationCodeModal: false }))
  }
}

export const generateUserActivationCodesForOrganization = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ activationCodeModal: true }))

  try {
    const response = await fetchingAPI(
      `${apiService.auth}/activation_codes?organization_id=${organizationId}`,
      'POST',
      dispatch
    )

    dispatch(setActivationCodesForOrganization(response))
  } catch (err) {
    dispatch(setRouteError('Failed to generate activation codes for this organizations users'))
  } finally {
    dispatch(setLoading({ activationCodeModal: false }))
  }
}

// TODO: Refactor toggleLoading, update to async/await
export const fetchUsers =
  ({ queryParam, toggleLoading, organizationId, dispatchAction }) =>
  (dispatch) => {
    const loadUsersQueryString =
      queryParam && queryParam !== 'active'
        ? `?${queryString.stringify({ [queryParam]: true })}`
        : ''
    const loadUsers = fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}/users${loadUsersQueryString}`,
      'GET',
      dispatch
    )
    const loadUserAppStatus = fetchingAPI(
      `${apiService.auth}/activation_codes?organization_id=${organizationId}`,
      'GET',
      dispatch
    )

    Promise.all([loadUsers, loadUserAppStatus])
      .then(([{ users, edit_config, edit_users }, userAppStatuses]) => {
        const usersWithAppStatus = buildUsersWithAppStatus(users, userAppStatuses)

        batch(() => {
          dispatch({
            type: dispatchAction || 'organizations/setUsers',
            payload: usersWithAppStatus,
          })
          dispatch(updateUserPermissions({ edit_config, edit_users }))
        })

        if (toggleLoading) toggleLoading()
      })
      .catch((err) => {
        console.error('fetchUsers failed', err)
        if (toggleLoading) toggleLoading()
      })
  }

// TODO: Refactor toggleLoading
export const createUser =
  ({ organizationId, user, updateUsers, toggleLoading }) =>
  async (dispatch) => {
    try {
      await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/users`,
        'POST',
        dispatch,
        JSON.stringify(user)
      )
      updateUsers(organizationId)
      toggleLoading()
    } catch (err) {
      if (err?.error_message) {
        dispatch(setRouteError(err.error_message))
      }
    }
  }

// TODO: Refactor toggleLoading
export const updateUser =
  ({ userId, userData, loadUsersData, queryParam, toggleLoading }) =>
  async (dispatch, getState) => {
    const userDataToSubmit = { ...userData }

    // if tag objects are passed instead of ids, then convert object into ids.
    if (userDataToSubmit?.tags?.length > 0 && typeof userDataToSubmit.tags[0] === 'object') {
      userDataToSubmit.tags = userDataToSubmit.tags.map((tag) => tag && tag.id)
    }

    try {
      const { organization_id } = await fetchingAPI(
        `${apiService.web}/api/users/${userId}`,
        'PATCH',
        dispatch,
        JSON.stringify(userDataToSubmit)
      )
      const oldUsers = getState().organizationUsers.users
      const users = oldUsers.map((user) =>
        user.id === userId ? { ...userData, tags: user.tags } : user
      )
      dispatch(setUsers(users))
      dispatch(loadUsersData({ organizationId: organization_id, queryParam, toggleLoading }))
    } catch (err) {
      if (err?.error_message) {
        dispatch(setRouteError(err.error_message))
      }
      console.error('updateUser failed', err)
    }
  }

// TODO: Refactor toggleLoading
export const restoreUser =
  ({ userId, organizationId, loadUsersData, toggleLoading, queryParam }) =>
  async (dispatch) => {
    try {
      await fetchingAPI(`${apiService.web}/api/users/${userId}`, 'POST', dispatch)

      dispatch(loadUsersData({ organizationId, queryParam, toggleLoading }))
    } catch (err) {
      // catching error
    }
  }

// TODO: Refactor toggleLoading, update to async/await
export const exportUsers =
  ({ organizationId, data, toggleLoading }) =>
  (dispatch) => {
    const body = JSON.stringify(data)
    toggleLoading()
    fetchingAPI(`${apiService.web}/api/export/${organizationId}/users`, 'POST', dispatch, body)
      .then((csvStream) => {
        // get stream reader from fetch response body stream
        const responseReader = csvStream.getReader()
        const streamProgress = { data: '' }
        // this function reads stream, then if done == true we download and clean up.
        const readStream = () =>
          responseReader.read().then(({ value, done }) => {
            if (done) {
              const encodedUri = URL.createObjectURL(
                new Blob([streamProgress.data], { type: 'text/csv' })
              )
              const link = document.createElement('a')
              link.setAttribute('href', encodedUri)
              link.setAttribute('download', 'users.csv')
              document.body.appendChild(link) // Required for FF
              link.click()
              document.body.removeChild(link)

              return toggleLoading()
            }
            // stream comes in as uint8array
            const decodedCsvData = new TextDecoder('utf-8').decode(value)
            streamProgress.data += decodedCsvData

            return readStream()
          })
        readStream()
      })
      .catch((err) => {
        console.error('loadCsvData failed', err)
        toggleLoading()
      })
  }
