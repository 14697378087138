import { isString, toLower } from 'lodash'

function lower(value) {
  if (isString(value)) {
    return toLower(value)
  }

  return value
}

export const sortArray = (a, b, isAscending) => {
  if (a[0] == null) {
    return 1
  }

  if (b[0] == null) {
    return -1
  }

  if (isAscending) {
    return lower(a[0].value) < lower(b[0].value) ? -1 : 1
  }

  return lower(a[0].value) < lower(b[0].value) ? 1 : -1
}

export const sortComparator = (a, b, orderBy, isAscending) => {
  if (
    typeof a[orderBy] === 'object' &&
    a[orderBy] != null &&
    typeof b[orderBy] === 'object' &&
    b[orderBy] != null
  ) {
    if (Array.isArray(b[orderBy]) && Array.isArray(a[orderBy])) {
      const bArray = b[orderBy]
      const aArray = a[orderBy]

      return sortArray(aArray, bArray, isAscending)
    }

    const objA = a[orderBy].value
    const objB = b[orderBy].value

    if (objB == null) {
      return -1
    }

    if (objA == null) {
      return 1
    }

    if (objA === objB) {
      return 0
    }

    if (isAscending) {
      return lower(objA) < lower(objB) ? -1 : 1
    }

    return lower(objA) < lower(objB) ? 1 : -1
  }

  if (b[orderBy] == null) {
    return -1
  }

  if (a[orderBy] == null) {
    return 1
  }

  if (lower(a[orderBy]) === lower(b[orderBy])) {
    return 0
  }

  if (isAscending) {
    return lower(a[orderBy]) < lower(b[orderBy]) ? -1 : 1
  }

  return lower(a[orderBy]) < lower(b[orderBy]) ? 1 : -1
}

export const getComparator = (order, orderBy) => {
  const isAscending = order !== 'desc'
  const comparatorOrderBy = orderBy === 'full_name' ? 'last_name' : orderBy
  return (a, b) => sortComparator(a, b, comparatorOrderBy, isAscending)
}

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])

    if (order !== 0) {
      return order
    }

    return a[1] - b[1]
  })

  return stabilizedThis.map((el) => el[0])
}

export function getPageBeginning(activePage, rowsPerPage, count) {
  if (count === 0) {
    return 0
  }

  // if the first page, the first entry is 1
  if (activePage === 1) {
    return 1
  }

  // Number of entries multiplied by the page, offset by one
  return rowsPerPage * (activePage - 1) + 1
}

export function getPageEnd(activePage, totalPages, rowsPerPage, count, pageBeginning) {
  if (count === 0) {
    return 0
  }

  // if the last page, last entry will be the total count
  if (activePage === totalPages) {
    return count
  }

  // Number of entries plus the first entry of the page, offset by one
  return pageBeginning + rowsPerPage - 1
}
