import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ProtectedRoute } from './routing/ProtectedRoute'
import { publicRoutes } from './routing/public.routes'
import { protectedRoutes } from './routing/protected.routes'

const App = () => {
  return (
    <Switch>
      {publicRoutes.map(({ exact = true, path, Component }) => (
        <Route exact={exact} key={path} path={path} component={Component} />
      ))}
      {protectedRoutes.map(({ exact = true, path, Component, ...props }) => (
        <ProtectedRoute exact={exact} key={path} path={path} component={Component} {...props} />
      ))}
    </Switch>
  )
}

export default App
