// A little API for the stateful socket connection, just to keep it out of the global
// namespace and away from the socket middleware
export default class SocketClient {
  constructor() {
    this.socket = null
    this.retries = 0
    // product wants 30s before we stop trying
    // 8^5 is 32768 ms or 32 seconds so we stop here
    this.maxRetries = 5
    this.retryBaseTime = 8 // ms
  }

  connect({ url, binaryType = null, username = '', token = '' }) {
    // if retry button is clicked reset the counter n try again
    if (this.retries > this.maxRetries) {
      this.retries = 0
    }
    if (!this.socket || this.socket.readyState === 3) {
      const urlContainsParams = url.includes('?') && url.includes('=')
      const authParams = `${urlContainsParams ? '&' : '?'}username=${username}&token=${token}`

      this.socket = new WebSocket(`${url}${authParams}`)
      if (binaryType) {
        this.socket.binaryType = binaryType
      }
    }
  }

  setHeartBeat(toReconnect) {
    this.tryToReconnect = true
    if (this.socket) {
      this.socket.ping = () => {
        const pingFrame = new Uint8Array(9)

        if (this.socket?.readyState === 1) {
          if (this.socket?.pongReceived === false) {
            this.socket.close()
          } else {
            this.socket.pongReceived = false
            this.socket.send(pingFrame)
          }
        }
      }

      this.socket.addEventListener('open', () => {
        this.retries = 0
        this.socket.heartBeatId = setInterval(this.socket.ping, 3000)
      })

      this.socket.addEventListener('message', ({ data: rawData }) => {
        if (rawData.size === 3) {
          // pong frame idk how to make byte variable for this
          this.socket.pongReceived = true
        }
      })

      this.socket.addEventListener('close', () => {
        clearInterval(this.socket?.heartBeatId)
        if (this.tryToReconnect && this.retries <= this.maxRetries) {
          setTimeout(() => {
            toReconnect()
            this.retries += 1
          }, this.retryBaseTime ** this.retries)
        }
      })
    }
  }

  disconnect() {
    this.tryToReconnect = false
    if (this.socket) {
      if (this.socket?.heartBeatId) {
        clearInterval(this?.socket?.heartBeatId)
      }
      this.socket.close()
      this.socket = null
    }
  }

  sendJson(message) {
    if (this.socket) {
      this.socket.send(JSON.stringify(message))
    }
  }

  on(eventName, func) {
    if (this.socket) {
      // if you have heartbeat u receive byte message for pong
      // could throw off your message handler so we just ignore
      // here and let specific on message above handle
      const ignorePongMessage = ({ data }) => {
        if (!(data.size === 3)) {
          func({ data })
        }
      }

      if (eventName === 'message' && this.socket?.ping) {
        this.socket.addEventListener(eventName, ignorePongMessage)
      } else {
        this.socket.addEventListener(eventName, func)
      }
    }
  }
}
