export const UNCATEGORIZED_TAGS = 'Uncategorized Tags'

export const groupByOptions = [
  { value: 'day', label: 'Daily' },
  { value: 'week', label: 'Weekly' },
  { value: 'month', label: 'Monthly' },
]

export const rankOnOptions = [
  { label: 'Checklist Usage', value: 'checklist_usage' },
  { label: 'Decklist Usage', value: 'decklist_usage' },
  { label: 'Win Count', value: 'wins' },
  { label: 'Win Rate', value: 'win_rate' },
  { label: 'QA Score', value: 'qa' },
]

export const timePeriodOptions = [
  { value: 'today', label: 'Today' },
  { value: 'this_week', label: 'This Week' },
  { value: 'this_month', label: 'This Month' },
  { value: 'last_7_days', label: 'Last 7 days' },
  { value: 'last_30_days', label: 'Last 30 days' },
]

export const RTCDisplayGroupingOptions = [
  { value: 'day', label: 'Day', accessor: 'day' },
  { value: 'manager', label: 'Manager', accessor: 'manager' },
  { value: 'agent', label: 'Agent', accessor: 'agent' },
  { value: 'tag', label: 'Tag', accessor: 'tag' },
  { value: 'call_id', label: 'Call ID', accessor: 'call_id' },
  { value: 'playbook', label: 'Playbook', accessor: 'playbook' },
  { value: 'alert_type', label: 'Alert Type', accessor: 'alert_type' },
  { value: 'alert_name', label: 'Alert Name', accessor: 'alert_name' },
  { value: 'trigger', label: 'Trigger', accessor: 'trigger' },
]

export const notApplicableChartColor = '#DEDFE1'
export const chartColors = [
  '#58A6FF',
  '#FFBA67',
  '#2FB475',
  '#FAACAC',
  '#367ACD',
  '#B06CFF',
  '#03A29C',
  '#697CFF',
  '#F56E6E',
  '#2CE1CE',
  '#FFDB55',
  '#FF6BB1',
]

export const thresholdColors = ['#F78D8D', '#FCCCCC', '#FFDBB0', '#BCEBD2']
