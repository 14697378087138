import moment from 'moment'

const startOfToday = moment().startOf('day').format()
const endOfToday = moment().endOf('day').format()

// Action Types
export const SET_CALLS = 'calls/setCalls'
export const SET_CALL = 'calls/setCall'
export const SET_FILTERS = 'calls/setFilters'
export const ENABLE_FILTERS = 'calls/shouldEnableFilters'
export const RESET_FILTERS = 'calls/resetFilters'
export const SET_DATE_RANGE_FILTERS = 'calls/setDateRangeFilters'
export const SET_LOADING = 'calls/setLoading'

// Action Creators
export const setCalls = (payload) => ({ type: SET_CALLS, payload })
export const setCall = (payload) => ({ type: SET_CALL, payload })
export const setFilters = (payload) => ({ type: SET_FILTERS, payload })
export const shouldEnableFilters = (payload) => ({ type: ENABLE_FILTERS, payload })
export const resetFilters = () => ({ type: RESET_FILTERS })
export const setDateRangeFilters = (payload) => ({ type: SET_DATE_RANGE_FILTERS, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })

// State
export const initialState = {
  calls: [],
  call: {},
  filters: {
    start_date: startOfToday,
    end_date: endOfToday,
    organization_id: '',
    uuid: '',
    voip_call_id: '',
  },
  isUpdateCallsEnabled: false,
  loading: false,
}

export default function callsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CALLS:
      return { ...state, calls: action.payload }
    case SET_CALL:
      return { ...state, call: action.payload }
    case SET_FILTERS:
      return { ...state, filters: { ...state.filters, ...action.payload } }
    case ENABLE_FILTERS:
      return { ...state, isUpdateCallsEnabled: action.payload }
    case RESET_FILTERS:
      return { ...state, filters: initialState.filters }
    case SET_DATE_RANGE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
        },
      }
    case SET_LOADING:
      return { ...state, loading: action.payload }
    default:
      return state
  }
}
