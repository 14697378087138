import React from 'react'
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronDoubleRightIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/solid'

import { getPageBeginning, getPageEnd } from './helpers'

export const AdvancedTablePagination = ({
  activePage,
  rowsPerPage,
  count,
  totalPages,
  setActivePage,
}) => {
  const pageBeginning = getPageBeginning(activePage, rowsPerPage, count)
  const pageEnd = getPageEnd(activePage, totalPages, rowsPerPage, count, pageBeginning)

  return (
    <div className="advanced-table__pagination" data-testid="advanced-table-pagination">
      <div className="advanced-table__pagination--details">
        {`${pageBeginning} – ${pageEnd} of ${count}`}
      </div>
      <div className="advanced-table__pagination--arrows">
        <button
          data-testid="pagination-first"
          disabled={activePage === 1}
          type="button"
          onClick={() => activePage !== 1 && setActivePage(1)}
        >
          <ChevronDoubleLeftIcon className="advanced-table__pagination--icon" />
        </button>
        <button
          disabled={activePage === 1}
          data-testid="pagination-prev"
          type="button"
          onClick={() => activePage !== 1 && setActivePage((prevState) => prevState - 1)}
        >
          <ChevronLeftIcon className="advanced-table__pagination--icon" />
        </button>
        <button
          data-testid="pagination-next"
          disabled={activePage === totalPages}
          type="button"
          onClick={() => activePage !== totalPages && setActivePage((prevState) => prevState + 1)}
        >
          <ChevronRightIcon className="advanced-table__pagination--icon" />
        </button>
        <button
          data-testid="pagination-last"
          disabled={activePage === totalPages}
          type="button"
          onClick={() => activePage !== totalPages && setActivePage(totalPages)}
        >
          <ChevronDoubleRightIcon className="advanced-table__pagination--icon" />
        </button>
      </div>
    </div>
  )
}
