import React from 'react'
import IntegrationsEditPage from '@/views/Integrations/IntegrationsEditPage'
import IntegrationsPage from '@/views/Integrations/IntegrationsPage'

const IndexRedirect = React.lazy(() => import('./IndexRedirect'))
const PlaybooksPage = React.lazy(() => import('../views/Playbooks/PlaybooksPage'))
const PlaybookEditorPage = React.lazy(() => import('../views/Playbooks/PlaybookEditorPage'))
const ConfigPage = React.lazy(() => import('../containers/Config'))
const ReportsPage = React.lazy(() => import('../containers/reports/Reports'))
const OrganizationsPage = React.lazy(() => import('../views/Organizations/OrganizationsPage'))
const OrganizationPage = React.lazy(() => import('../views/Organizations/OrganizationPage'))
const TagPage = React.lazy(() => import('../views/Organizations/TagPage'))
const OrganizationFlagsPage = React.lazy(() =>
  import('../views/Organizations/OrganizationFlagsPage')
)
const CallsPage = React.lazy(() => import('../views/Calls/CallsPage'))
const CallSearchPage = React.lazy(() => import('../views/Calls/CallSearchPage'))
const CallPage = React.lazy(() => import('../views/Calls/CallPage'))
const CallExplorerPage = React.lazy(() => import('../views/Calls/CallExplorerPage'))
const KeywordRepositoryPage = React.lazy(() =>
  import('../views/KeywordRepository/KeywordRepositoryPage')
)
const LeaderboardsPage = React.lazy(() => import('../views/Leaderboards/LeaderboardsPage'))
const AnalyticsPage = React.lazy(() => import('../views/Analytics/AnalyticsPage'))
const ScorecardsPage = React.lazy(() => import('../views/Scorecards/Scorecards'))
const RealtimeCoachingPage = React.lazy(() => import('../views/RealtimeCoaching/RealtimeCoaching'))
const ScorecardEditorPage = React.lazy(() => import('../views/Scorecards/ScorecardEditorWrapper'))
const ViewScoresPage = React.lazy(() => import('../views/Scorecards/ViewScoresPage/ViewScoresPage'))
const ScorecardDashboardPage = React.lazy(() =>
  import('../views/Scorecards/DashboardPage/DashboardPage')
)

const ScorecardExportPage = React.lazy(() =>
  import('../views/Scorecards/ExportsPage/ExportsDashboard')
)
const ChallengesPage = React.lazy(() =>
  import('@/views/Gamification/Challenges/ChallengesPage/ChallengesPage')
)
const BadgesPage = React.lazy(() => import('@/views/Gamification/Badges/BadgesPage'))
const BadgeWizard = React.lazy(() => import('@/views/Gamification/Badges/BadgeWizard/BadgeWizard'))
const ChallengeDetailPage = React.lazy(() =>
  import('@/views/Gamification/Challenges/ChallengeDetailPage/ChallengeDetailPage')
)
const BadgeDetailPage = React.lazy(() =>
  import('@/views/Gamification/Badges/BadgeDetailPage/BadgeDetailPage')
)

const LaunchDarklyFlagsPage = React.lazy(() =>
  import('@/views/LaunchDarklyFlags/LaunchDarklyFlagsPage')
)

export const protectedRoutes = [
  { path: '/', Component: IndexRedirect },
  { path: '/playbooks', Component: PlaybooksPage },
  { path: '/playbooks/:id', Component: ConfigPage },

  // New playbook editor
  { path: '/playbooks/:id/:section', Component: PlaybookEditorPage },
  { path: '/playbooks/:id/:section/:entryId', Component: PlaybookEditorPage },

  { path: '/dashboards/:section', Component: ReportsPage },
  { path: '/reports', Component: AnalyticsPage },
  { path: '/reports/:section', Component: AnalyticsPage },

  { path: '/users', Component: OrganizationPage },
  { path: '/users/tags', Component: TagPage },

  { path: '/leaderboards', Component: LeaderboardsPage },

  // QA Routes
  { path: '/scorecards', Component: ScorecardsPage, qaRoute: true },
  { path: '/scorecards/dashboard', Component: ScorecardDashboardPage, qaRoute: true },
  {
    path: '/scorecards/dashboard/overview',
    Component: ScorecardDashboardPage,
    qaRoute: true,
    componentProps: { tab: 'Overview' },
  },
  {
    path: '/scorecards/dashboard/live',
    Component: ScorecardDashboardPage,
    qaRoute: true,
    componentProps: { tab: 'Live Feed' },
  },
  { path: '/scorecards/view_scores', Component: ViewScoresPage, qaRoute: true },
  { path: '/scorecards/exports', Component: ScorecardExportPage, qaRoute: true },
  {
    path: '/scorecards/edit_config/:scorecard_config_id',
    Component: ScorecardEditorPage,
    qaRoute: true,
  },
  {
    path: '/scorecards/edit_config/:scorecard_config_id/edit_section/:scorecard_section_id',
    Component: ScorecardEditorPage,
    qaRoute: true,
  },
  // Gamification Routes
  {
    path: '/gamification/challenges',
    Component: ChallengesPage,
  },
  {
    path: '/gamification/badges',
    Component: BadgesPage,
    componentProps: { tab: 'Collections' },
  },
  {
    path: '/gamification/badges/collections',
    Component: BadgesPage,
    componentProps: { tab: 'Collections' },
  },
  {
    path: '/gamification/badges/challenges',
    Component: BadgesPage,
    componentProps: { tab: 'Challenges' },
  },
  {
    path: '/gamification/badges/wizard',
    Component: BadgeWizard,
  },
  {
    path: '/gamification/challenges/:challenge_id',
    Component: ChallengeDetailPage,
  },
  {
    path: '/gamification/badges/:badge_id',
    Component: BadgeDetailPage,
  },
  // Real Time Coaching Routes
  { path: '/realtime_coaching/:section', Component: RealtimeCoachingPage },
  { path: '/realtime_coaching/alerts/:alert_config_id', Component: RealtimeCoachingPage },
  { path: '/realtime_coaching/reports/:subsection', Component: RealtimeCoachingPage },

  // Admin Routes
  { path: '/organizations', Component: OrganizationsPage, adminOnly: true },
  { path: '/organizations/:organizationid', Component: OrganizationPage, adminOnly: true },
  { path: '/organizations/:organizationid/tags', Component: TagPage, adminOnly: true },
  {
    path: '/organizations/:organizationid/flags',
    Component: OrganizationFlagsPage,
    adminOnly: true,
  },
  { path: '/calls', Component: CallsPage, adminOnly: true },
  { path: '/calls2', Component: CallSearchPage },
  { path: '/calls/:id', Component: CallPage, adminOnly: true },
  { path: '/call-explorer/:id', Component: CallExplorerPage },
  {
    path: '/keywords_repository/:language(english|spanish)?',
    Component: KeywordRepositoryPage,
    adminOnly: true,
  },
  { path: '/integrations', Component: IntegrationsPage, adminOnly: true },
  {
    path: '/integrations/edit_config/:integration_config_id',
    Component: IntegrationsEditPage,
    qaRoute: true,
    componentProps: { tab: 'Connector Config' },
  },
  { path: '/ldflags', Component: LaunchDarklyFlagsPage, adminOnly: true },
]
