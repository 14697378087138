import React from 'react'
import { Accordion, Dropdown, Loader } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, NavLink } from 'react-router-dom'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import {
  PhoneIcon,
  ChartBarIcon,
  GlobeAltIcon,
  CheckCircleIcon,
  AcademicCapIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ArrowLeftOnRectangleIcon,
  PlayCircleIcon,
  UserCircleIcon,
  PuzzlePieceIcon,
  FlagIcon,
} from '@heroicons/react/24/solid'
import { toggleSidebarCollapsed } from '@/reducers/ui/ui.redux'
import { unauthenticateUser } from '@/reducers/auth/currentUser.actions'
import './globalSidebar.scss'
import { TrophyIcon } from '../../icons/TrophyIcon'
import { BaltoLogo } from '../../BaltoLogo'

const reportLinks = [
  { path: '/reports', label: 'Overview', exact: true },
  { path: '/reports/checklist', label: 'Checklist' },
  { path: '/reports/deck', label: 'Deck' },
  { path: '/reports/notifications', label: 'Notifications' },
  { path: '/reports/postcall', label: 'Post Call' },
  { path: '/reports/usage', label: 'Usage' },
  { path: '/dashboards/csv', label: 'Export CSV' },
]

const scorecardLinks = [
  { path: '/scorecards', label: 'Scorecards', exact: true },
  { path: '/scorecards/view_scores', label: 'Scores' },
  { path: '/scorecards/dashboard', label: 'Dashboard' },
]

const realTimeLinks = [
  { path: '/realtime_coaching/alerts', label: 'Alerts' },
  { path: '/realtime_coaching/activity-log', label: 'Activity Log' },
  { path: '/realtime_coaching/reports', label: 'Reports' },
]

const gamificationLinks = [
  { path: '/dashboards/leaderboards', label: 'Leaderboards' },
  { path: '/gamification/challenges', label: 'Challenges' },
  { path: '/gamification/badges', label: 'Badges' },
]

function SidebarItemsAccordion({ items }) {
  return (
    <ul className="balto-sidebar__collapse-menu--content">
      {items.map((item) => (
        <li key={`${item.path}${item.label}`}>
          <NavLink key={item.path} to={item.path} exact={item.exact}>
            {item.label}
          </NavLink>
        </li>
      ))}
    </ul>
  )
}

function SidebarItemsDropdown({ items }) {
  return (
    <>
      {items.map((item) => (
        <NavLink key={item.path} to={item.path} exact={item.exact} className="item">
          {item.label}
        </NavLink>
      ))}
    </>
  )
}

const GlobalSidebarComponent = ({ flags, ldClient }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { sidebarCollapsed } = useSelector((state) => state.ui)
  const {
    organizationid,
    real_time_management_access: deprecatedRtcAccess,
    realtime_coaching_access: userRealtimeCoachingAccess,
    org_realtime_coaching_access: orgRealtimeCoachingAccess,
    edit_qa: scorecardAccess,
    game_admin: isGameAdmin,
    game_operator: isGameOperator,
    game_monitor: isGameMonitor,
  } = useSelector((state) => state.currentUser)
  const {
    loading: customBrandingLoading,
    customBranding,
    imageCache,
  } = useSelector((state) => state.customBranding)

  const isAdmin = organizationid === 1
  const isGamificationLink =
    location.pathname.includes('gamification') || location.pathname.includes('leaderboards')
  const showGamificationLinks = isGameAdmin || isGameOperator || isGameMonitor
  const shouldShowCustomLogo = customBranding.iconDarkUrl || customBranding.iconLightUrl
  const customLogoUrl = `${shouldShowCustomLogo}?${imageCache}`

  const handleLogout = (e) => {
    e.preventDefault()

    history.push('/login')
    // if the user is prompted to confirm and cancels, we don't want to clear
    if (history.location.pathname === '/login') {
      dispatch(unauthenticateUser({ history, location, ldClient }))
    }
  }

  const handleCollapse = (e) => {
    e.preventDefault()
    dispatch(toggleSidebarCollapsed(!sidebarCollapsed))
  }

  const ReportingPanel = [
    {
      key: 'Reporting',
      title: {
        content: (
          <>
            <ChartBarIcon />
            <span className="balto-sidebar__collapse-menu--header-title">Reporting</span>
            <ChevronDownIcon className="caret-icon" />
          </>
        ),
        icon: false,
      },
      content: {
        content: (
          <SidebarItemsAccordion
            items={
              isAdmin
                ? [
                    ...reportLinks,
                    { path: '/dashboards/troubleshooting', label: 'Troubleshooting' },
                  ]
                : reportLinks
            }
          />
        ),
      },
    },
  ]
  const QaScorecardsPanel = [
    {
      key: 'QAScorecards',
      title: {
        content: (
          <>
            <CheckCircleIcon />
            <span className="balto-sidebar__collapse-menu--header-title">QA Scorecards</span>
            <ChevronDownIcon className="caret-icon" />
          </>
        ),
        icon: false,
      },
      content: {
        content: (
          <SidebarItemsAccordion
            items={
              flags?.cloudRtqAdvancedExports2022 || isAdmin
                ? [...scorecardLinks, { path: '/scorecards/exports', label: 'Advanced Exports' }]
                : scorecardLinks
            }
          />
        ),
      },
    },
  ]

  const GamificationPanel = [
    {
      key: 'Gamification',
      title: {
        content: (
          <>
            <TrophyIcon />
            <span className="balto-sidebar__collapse-menu--header-title">Gamification</span>
            <ChevronDownIcon className="caret-icon" />
          </>
        ),
        icon: false,
      },
      content: {
        content: <SidebarItemsAccordion items={gamificationLinks} />,
      },
    },
  ]

  const RealTimeCoachingPanel = [
    {
      key: 'RealTimeCoaching',
      title: {
        content: (
          <>
            <AcademicCapIcon />
            <span className="balto-sidebar__collapse-menu--header-title">Real-Time Coaching</span>
            <ChevronDownIcon className="caret-icon" />
          </>
        ),
        icon: false,
      },
      content: {
        content: (
          <SidebarItemsAccordion
            items={
              flags?.cloudRtcAdvancedExports2022
                ? [
                    ...realTimeLinks,
                    { path: '/realtime_coaching/exports', label: 'Advanced Exports' },
                  ]
                : realTimeLinks
            }
          />
        ),
      },
    },
  ]

  return (
    <aside className={`balto-sidebar ${sidebarCollapsed ? 'collapse' : ''}`}>
      <div className="balto-sidebar__collapse-link">
        <NavLink to="/reports">
          {customBrandingLoading ? (
            <Loader inline active style={{ marginRight: '1rem' }} />
          ) : (
            <>
              {shouldShowCustomLogo ? (
                <img src={customLogoUrl} alt="App Logo" className="custom-app-logo" />
              ) : (
                <BaltoLogo
                  height={18}
                  width={24}
                  fill="#ffffff"
                  style={{ margin: '0 1rem 0 -0.25rem' }}
                />
              )}
            </>
          )}
          <span className="balto-sidebar__header-link--label">
            {customBrandingLoading ? '' : customBranding.customBrandingDisplayName || 'Balto'}
          </span>
        </NavLink>
        {!sidebarCollapsed && (
          <>
            <div onClick={handleCollapse} className="collapse-btn">
              <ChevronDoubleLeftIcon />
            </div>
          </>
        )}
        {sidebarCollapsed && (
          <div onClick={handleCollapse} className="collapse-btn collapsed">
            <ChevronDoubleRightIcon />
          </div>
        )}
      </div>

      {/* Organization and Calls Links */}
      {isAdmin && (
        <>
          <NavLink to="/organizations" className="balto-sidebar__title-link">
            <GlobeAltIcon />
            <span className="balto-sidebar__collapse-menu--header-title">Organizations</span>
          </NavLink>
          <NavLink to="/calls" className="balto-sidebar__title-link">
            <PhoneIcon />
            <span className="balto-sidebar__collapse-menu--header-title">Calls</span>
          </NavLink>
          <NavLink to="/integrations" className="balto-sidebar__title-link">
            <PuzzlePieceIcon />
            <span className="balto-sidebar__collapse-menu--header-title">Integrations</span>
          </NavLink>
          <NavLink to="/ldflags" className="balto-sidebar__title-link">
            <FlagIcon />
            <span className="balto-sidebar__collapse-menu--header-title">LaunchDarkly Flags</span>
          </NavLink>
        </>
      )}

      {flags?.cloudCallsExplorer2022 && (
        <NavLink to="/calls2" className="balto-sidebar__title-link">
          <PhoneIcon />
          <span className="balto-sidebar__collapse-menu--header-title">Call Explorer</span>
        </NavLink>
      )}

      {/* Playbooks Links */}
      <NavLink to="/playbooks" className="balto-sidebar__title-link">
        <PlayCircleIcon />
        <span className="balto-sidebar__collapse-menu--header-title">Playbooks</span>
      </NavLink>

      {/* User Links */}
      {!isAdmin && (
        <NavLink to="/users" className="balto-sidebar__title-link">
          <UserCircleIcon />
          <span className="balto-sidebar__collapse-menu--header-title">Users</span>
        </NavLink>
      )}

      {/* Reporting Links */}
      {!sidebarCollapsed && (
        <Accordion
          defaultActiveIndex={
            location.pathname.startsWith('/reports') || location.pathname.includes('dashboards')
              ? 0
              : -1
          }
          panels={ReportingPanel}
        />
      )}

      {sidebarCollapsed && (
        <Dropdown
          text={<ChartBarIcon />}
          pointing="left"
          className="link item sidebar-collapse-trigger"
        >
          <Dropdown.Menu>
            <SidebarItemsDropdown
              items={
                isAdmin
                  ? [
                      ...reportLinks,
                      { path: '/dashboards/troubleshooting', label: 'Troubleshooting' },
                    ]
                  : reportLinks
              }
            />
          </Dropdown.Menu>
        </Dropdown>
      )}

      {/* Leaderboards Links Expanded Sidebar */}
      {!sidebarCollapsed &&
        (isAdmin || showGamificationLinks ? (
          <Accordion panels={GamificationPanel} defaultActiveIndex={isGamificationLink ? 0 : -1} />
        ) : (
          <NavLink to="/dashboards/leaderboards" className="balto-sidebar__title-link">
            <TrophyIcon />
            <span className="balto-sidebar__collapse-menu--header-title">Leaderboards</span>
          </NavLink>
        ))}

      {/* Leaderboards Links Collapsed Sidebar */}
      {sidebarCollapsed &&
        (isAdmin || showGamificationLinks ? (
          <Dropdown
            text={<TrophyIcon />}
            pointing="left"
            className="link item sidebar-collapse-trigger"
          >
            <Dropdown.Menu>
              <SidebarItemsDropdown items={gamificationLinks} />
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <NavLink to="/dashboards/leaderboards" className="balto-sidebar__title-link">
            <TrophyIcon />
            <span className="balto-sidebar__collapse-menu--header-title">Leaderboards</span>
          </NavLink>
        ))}

      {/* QA Scorecards Links */}
      {(scorecardAccess || isAdmin) && !sidebarCollapsed && (
        <Accordion
          panels={QaScorecardsPanel}
          defaultActiveIndex={location.pathname.includes('scorecards') ? 0 : -1}
        />
      )}

      {(scorecardAccess || isAdmin) && sidebarCollapsed && (
        <Dropdown
          text={<CheckCircleIcon />}
          pointing="left"
          className="link item sidebar-collapse-trigger"
        >
          <Dropdown.Menu>
            <SidebarItemsDropdown items={scorecardLinks} />
          </Dropdown.Menu>
        </Dropdown>
      )}

      {/* Real-Time Coaching Links */}
      {(userRealtimeCoachingAccess || deprecatedRtcAccess) &&
        orgRealtimeCoachingAccess &&
        !sidebarCollapsed && (
          <Accordion
            defaultActiveIndex={location.pathname.includes('realtime_coaching') ? 0 : -1}
            panels={RealTimeCoachingPanel}
          />
        )}

      {(userRealtimeCoachingAccess || deprecatedRtcAccess) &&
        orgRealtimeCoachingAccess &&
        sidebarCollapsed && (
          <Dropdown
            text={<AcademicCapIcon />}
            pointing="left"
            className="link item sidebar-collapse-trigger"
          >
            <Dropdown.Menu>
              <SidebarItemsDropdown
                items={
                  flags?.cloudRtcAdvancedExports2022
                    ? [
                        ...realTimeLinks,
                        { path: '/realtime_coaching/exports', label: 'Advanced Exports' },
                      ]
                    : realTimeLinks
                }
              />
            </Dropdown.Menu>
          </Dropdown>
        )}

      {!sidebarCollapsed && (
        <div className="balto-sidebar__footer">
          <a className="balto-sidebar__title-link" onClick={handleLogout}>
            <ArrowLeftOnRectangleIcon />
            <span
              data-testid="sign-out-button"
              className="balto-sidebar__collapse-menu--header-title"
            >
              Sign out
            </span>
          </a>
        </div>
      )}
    </aside>
  )
}

export const GlobalSidebar = withLDConsumer()(GlobalSidebarComponent)
