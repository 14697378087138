import React from 'react'

export const TrophyIcon = () => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1C4 0.723858 4.22386 0.5 4.5 0.5H13.5C13.7761 0.5 14 0.723858 14 1V2.5H16.5C17.3284 2.5 18 3.17157 18 4V5.5C18 8.2613 15.7616 10.4998 13.0004 10.5C12.2791 11.4602 11.2203 12.1523 10 12.4V14.5H13C13.5523 14.5 14 14.9477 14 15.5C14 16.0523 13.5523 16.5 13 16.5H5C4.44772 16.5 4 16.0523 4 15.5C4 14.9477 4.44772 14.5 5 14.5H8V12.4C6.77975 12.1523 5.72087 11.4602 4.99963 10.5C2.23838 10.4998 0 8.2613 0 5.5V4C0 3.17157 0.671573 2.5 1.5 2.5H4V1ZM4 4.5H2.5C2.22386 4.5 2 4.72386 2 5V5.5C2 6.8332 2.86965 7.96329 4.07263 8.35392C4.02488 8.07643 4 7.79112 4 7.5V4.5ZM13.9274 8.35392C15.1303 7.96329 16 6.8332 16 5.5V5C16 4.72386 15.7761 4.5 15.5 4.5H14V7.5C14 7.79112 13.9751 8.07643 13.9274 8.35392Z"
        fill="white"
      />
    </svg>
  )
}
