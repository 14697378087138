import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Segment } from 'semantic-ui-react'
import {
  mergeOrgsandIntegrations,
  formatHealthValue,
  getStatusClassName,
  getConnectorDisplayType,
  getAndCheckOrgColumn,
} from '@/views/Integrations/helpers'
import { Link, useHistory } from 'react-router-dom'
import { IntegrationsForm } from '@/views/Integrations/IntegrationsForm'
import { fetchIntegrations, deleteIntegration } from '@/reducers/integrations/integrations.actions'
import { setRouteError } from '@/reducers/errors/routeErrors.redux'
import { AdvancedTable } from '../../components/tables/AdvancedTable/AdvancedTable'
import Form from '../../components/Modal'
import ConfirmForm from '../../components/forms/ConfirmForm'
import { ButtonAndFormModal } from '../../components/layout/modals/ButtonAndFormModal'

const IntegrationsPage = () => {
  const dispatch = useDispatch()
  const { integrations, loading } = useSelector((state) => state.integrations)
  const organizations = useSelector((state) => state.organizations)
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchIntegrations())
  }, [])

  const columns = [
    {
      accessor: 'name',
      isSearchable: true,
      label: 'Name',
    },
    {
      accessor: 'type',
      isSearchable: true,
      label: 'Connector Type',
      format: getConnectorDisplayType,
    },
    {
      accessor: 'organization_name',
      label: 'Organization',
      isSearchable: true,
    },
    {
      accessor: 'organization_id',
      label: 'Org Id',
      isSearchable: false,
    },
    {
      accessor: 'status',
      label: 'Status',
    },
    {
      accessor: 'healthScore',
      label: 'Health Score',
      format: formatHealthValue,
    },
    {
      accessor: 'modified_date',
      label: 'Last Updated',
      format: (value) => moment(value).format('MMM Do, YYYY hh:mm a'),
    },
  ]

  const actions = [
    {
      label: 'Delete',
      trigger: (row) => (
        <Form
          popupContent="Delete Integration"
          header="Delete Integration"
          icon="trash"
          muted
          modalSize="tiny"
          form={
            <ConfirmForm
              bodyText={`Are you sure you would like to delete [${row.name.value}]'s [${row.type}] integration config?`}
              primaryButtonText="Delete"
              handleSubmit={() => {
                if (row.active) {
                  dispatch(setRouteError(`Must be deactivated before deletion`))
                } else {
                  dispatch(deleteIntegration({ id: row.id, push: history.push }))
                }
              }}
            />
          }
        />
      ),
    },
  ]

  const rows = mergeOrgsandIntegrations(integrations, organizations).map((integration) => ({
    ...integration,
    name: {
      as: (
        <Link to={`/integrations/edit_config/${integration.id}`} className="table-link">
          {' '}
          {integration.name}
        </Link>
      ),
      value: integration.name,
    },
    status: {
      as: (
        <div className={getStatusClassName(integration.status)}>
          {integration.status.replace('_', ' ')}
        </div>
      ),
      value: integration.status,
    },
    organization_id: {
      as: getAndCheckOrgColumn(integration.organization_id),
      value: integration.organization_id,
    },
  }))
  return (
    <>
      <header className="page-header" data-testid="integrations-page">
        <h1>Integrations</h1>
        <ButtonAndFormModal
          buttonLabel="Create Integration"
          modalTitle="Create Integration"
          modalId="integrations/create"
          modalProps={{ size: 'tiny' }}
          form={<IntegrationsForm />}
        />
      </header>

      <Segment className="not-padded">
        <AdvancedTable
          defaultOrderBy="name"
          loading={loading}
          columns={columns}
          rows={rows}
          actions={actions}
          stickyHeader
          stickyAction
          pagination
        />
      </Segment>
    </>
  )
}

export default IntegrationsPage
