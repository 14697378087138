import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import Helmet from 'react-helmet'

import { buildUserAttributes } from '@/utils/launchDarkly'
import { Content } from '@/components/layout/Content'
import { Wrapper } from '@/components/layout/Wrapper'
import { GlobalSidebar } from '@/components/navigation/GlobalSidebar/GlobalSidebar'
import { unauthenticateUser } from '@/reducers/auth/currentUser.actions'
import { loginUser } from '@/reducers/auth/currentUser.redux'
import { AlertSidebar } from '@/views/RealtimeCoaching/Alerts/AlertSidebar/AlertSidebar'
import { initializePendo } from '@/utils/initializePendo'
import { pageTitles } from './pageTitles'

import favicon from '../assets/images/favicon-32x32.png'

const ErrorWrapper = React.lazy(() => import('../components/layout/ErrorWrapper'))

const ProtectedRouteComponent = ({
  component: Component,
  adminOnly,
  qaRoute,
  componentProps,
  ldClient,
  ...rest
}) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [checkedAuth, setCheckedAuth] = useState(false)
  const {
    token,
    organizationid,
    real_time_management_access: deprecatedRtcAccess,
    realtime_coaching_access: userRealtimeAccess,
    org_realtime_coaching_access: orgRealtimeAccess,
    edit_qa: qaAccess,
  } = useSelector((state) => state.currentUser)
  const { customBranding } = useSelector((state) => state.customBranding)

  const isAdmin = organizationid === 1
  const faviconUrl = customBranding?.faviconUrl || favicon
  const displayName = customBranding?.customBrandingDisplayName || 'Balto Cloud'
  const pageTitle = pageTitles[location.pathname]
  const title = `${displayName} ${pageTitle ? `| ${pageTitle}` : ''}`

  useEffect(() => {
    if (!token) {
      const user = JSON.parse(localStorage.getItem('User'))

      if (user) {
        dispatch(loginUser(user))
        initializePendo()

        if (ldClient) {
          ldClient.identify(buildUserAttributes(user))
        }
      } else {
        dispatch(unauthenticateUser({ history, location, ldClient }))
      }
    } else {
      window.addEventListener('load', initializePendo)
    }

    setCheckedAuth(true)
  }, [])

  // Don't render component before auth is checked
  if (!checkedAuth) {
    return <Loader active data-testid="protected-route-loading" />
  }

  // Redirect admin routes if not an admin
  if (adminOnly && !isAdmin) {
    return <Redirect to="/" />
  }

  // Redirect permissionless QA routes
  if (qaRoute && !qaAccess && !isAdmin) {
    return <Redirect to="/" />
  }

  return (
    <Route {...rest}>
      <Helmet>
        <link href={faviconUrl} type="image/png" rel="shortcut icon" />
        <title>{title}</title>
      </Helmet>
      <Wrapper>
        <GlobalSidebar />
        <Content>
          <ErrorWrapper />
          <Component location={location} history={history} {...componentProps} />
        </Content>
        {(userRealtimeAccess || deprecatedRtcAccess) && orgRealtimeAccess && <AlertSidebar />}
      </Wrapper>
    </Route>
  )
}

export const ProtectedRoute = withLDConsumer()(ProtectedRouteComponent)
