export const pageTitles = {
  '/organizations': 'Organizations',
  '/calls': 'Calls',
  '/calls2': 'Calls',
  '/call-explorer': 'Call Explorer',
  '/integrations': 'Integrations',
  '/ldflags': 'Launch Darkly Flags',
  '/playbooks': 'Playbooks',
  '/recommendations': 'Recommendations',
  '/users': 'Users',
  '/leaderboards': 'Leaderboards',
  '/scorecards': 'Scorecards',
}
