import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import './integrations.css'
import IntegrationsSettings from '@/views/Integrations/IntegrationsSettings'
import IntegrationsMonitoring from '@/views/Integrations/IntegrationsMonitoring'
import IntegrationsLogs from '@/views/Integrations/IntegrationsLogs'
import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import { Breadcrumbs } from '@/components/forms/Breadcrumbs/Breadcrumbs'
import {
  activateIntegration,
  fetchIntegration,
  resetHealthScore,
} from '@/reducers/integrations/integrations.actions'
import ConfirmForm from '../../components/forms/ConfirmForm'

// redux information is dispatched from the Wrapper for both section and config editor
export const IntegrationEditor = () => {
  const dispatch = useDispatch()
  const { integration, activating, dirty, healthScore } = useSelector((state) => state.integrations)
  const { integration_config_id } = useParams()

  useEffect(() => {
    dispatch(fetchIntegration(integration_config_id))
  }, [])

  const backLinks = {
    label: 'Integrations',
    link: '/integrations',
  }

  const handleActivate = () => {
    dispatch(
      activateIntegration({
        id: integration.id,
        values: { ...integration, active: !integration?.active },
      })
    )
  }

  const handleResetHealthScore = () => {
    dispatch(resetHealthScore(integration))
  }

  return (
    <div className="integration-editor-content" data-testid="integration-editor">
      <div className="page-header editor-top-menu">
        <Breadcrumbs backLinks={backLinks} currentLink={integration?.name} />
        <div
          className="d-flex ml-auto"
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ButtonAndFormModal
            buttonLabel={integration.active ? 'Deactivate' : 'Activate'}
            modalTitle="Change Integration Activation"
            modalId="integrations/activate"
            modalProps={{ size: 'tiny' }}
            buttonProps={{ loading: activating }}
            disabled={dirty}
            form={
              <ConfirmForm
                valueToSubmit={integration?.active}
                bodyText={
                  integration?.active
                    ? 'Are you sure you want to deactivate this integration'
                    : 'Are you sure you want to activate this integration'
                }
                primaryButtonText={integration?.active ? 'Deactivate' : 'Activate'}
                handleSubmit={handleActivate}
              />
            }
          />

          <ButtonAndFormModal
            buttonLabel="Reset HealthScore"
            modalTitle="Reset Integration HealthScore"
            modalId="integrations/reset_healthscore"
            modalProps={{ size: 'tiny' }}
            buttonProps={{ loading: healthScore }}
            disabled={dirty}
            form={
              <ConfirmForm
                valueToSubmit={integration?.healthScore}
                bodyText="Are you sure you want to reset the healthscore for this integration?"
                primaryButtonText="Reset HealthScore"
                handleSubmit={handleResetHealthScore}
              />
            }
          />
        </div>
        {/* primary because we don't have the other button yet */}
      </div>
      <div className="ui segment">
        <IntegrationsMonitoring />
        <IntegrationsSettings />
        <IntegrationsLogs />
      </div>
    </div>
  )
}

export default IntegrationEditor
