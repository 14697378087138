import React from 'react'
import { Form, Radio } from 'semantic-ui-react'

export const RadioGroup = ({
  label,
  field: { name, value, ...fieldProps },
  form: { touched, errors },
  required,
  options,
  inline = true,
  ...props
}) => {
  return (
    <>
      <Form.Field
        required={required}
        error={errors[name] && touched[name] ? { content: errors[name] } : false}
        label={label}
      />
      <Form.Group grouped={!inline}>
        {options.map((option) => (
          <Form.Field key={option.value}>
            <Radio
              id={option.value}
              label={option.text || option.label}
              value={option.value}
              checked={option.value === value}
              disabled={option.disabled}
              name={name}
              data-testid={`radioGroup-${option.value}`}
              {...fieldProps}
              {...props}
            />
          </Form.Field>
        ))}
      </Form.Group>
    </>
  )
}
