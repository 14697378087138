import { toast } from 'react-toastify'

import { fetchingAPI, apiService } from '../../api'
import {
  setActivating,
  setCategories,
  setIntegration,
  setIntegrations,
  setLoading,
  setLogs,
  setOutcomes,
} from './integrations.redux'
import { setRouteError } from '../errors/routeErrors.redux'

const getHealthScore = async (integration) => {
  let healthScore
  try {
    const healthResponse = await fetch(
      `${apiService.remoteHealth}/org/${integration.organization_id}/conversation/health`
    )
    healthScore = (await healthResponse.json()).health_score
  } catch (err) {
    healthScore = undefined
  }
  return healthScore
}

export const fetchIntegrations = () => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    const integrations = await fetchingAPI(`${apiService.web}/api/integration/`, 'GET', dispatch)
    for (const integration of integrations) {
      integration.healthScore = await getHealthScore(integration)
    }

    dispatch(setIntegrations(integrations))
    dispatch(setLogs({}))
  } catch (err) {
    dispatch(setRouteError(`Error retrieving integrations`))
  } finally {
    dispatch(setLoading(false))
  }
}

export const fetchIntegration = (id) => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    const integration = await fetchingAPI(
      `${apiService.web}/api/integration/${id}`,
      'GET',
      dispatch
    )
    integration.healthScore = await getHealthScore(integration)
    dispatch(setIntegration(integration))
  } catch (err) {
    dispatch(setRouteError(`Error retrieving integration`))
  } finally {
    dispatch(setLoading(false))
  }
}

export const createIntegration =
  ({ values, push }) =>
  async (dispatch) => {
    try {
      const integration = await fetchingAPI(
        `${apiService.web}/api/integration/`,
        'POST',
        dispatch,
        JSON.stringify({ ...values, active: false })
      )
      dispatch(setIntegration(integration))
      dispatch(fetchIntegration(integration.id))
      push(`/integrations/edit_config/${integration.id}`)
    } catch (err) {
      dispatch(setRouteError(`Error creating integration: ${err.detail}`))
    }
  }

export const deleteIntegration =
  ({ id, push }) =>
  async (dispatch) => {
    try {
      await fetchingAPI(`${apiService.web}/api/integration/${id}`, 'DELETE', dispatch)
      dispatch(fetchIntegrations())
      push(`/integrations`)
    } catch (err) {
      // catching error
      dispatch(setRouteError(`Error deleting integration: ${id}`))
    }
  }

export const activateIntegration =
  ({ id, values }) =>
  async (dispatch) => {
    try {
      dispatch(setActivating(true))
      await fetchingAPI(
        `${apiService.web}/api/integration/${id}/activate`,
        'POST',
        dispatch,
        JSON.stringify(values)
      )
      dispatch(fetchIntegration(id))
      toast.success(`Integration ${values.active ? 'Activated' : 'Deactivated'}`, {
        containerId: 'right',
      })
      dispatch(setActivating(false))
    } catch (err) {
      // catching error
      dispatch(setActivating(false))
      dispatch(setRouteError(`Error activating integration: ${id}`))
    }
  }

export const updateIntegration =
  ({ id, values }) =>
  async (dispatch) => {
    try {
      await fetchingAPI(
        `${apiService.web}/api/integration/${id}`,
        'PUT',
        dispatch,
        JSON.stringify(values)
      )
      dispatch(fetchIntegration(id))
    } catch (err) {
      dispatch(setRouteError(`Error updating integration: ${id}`))
    }
  }

export const getDDLogs =
  ({ id, values }) =>
  async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const logs = await fetchingAPI(
        `${apiService.web}/api/integration/logs`,
        'POST',
        dispatch,
        JSON.stringify(values)
      )
      dispatch(setLogs(logs))
    } catch (err) {
      dispatch(setRouteError(`Error getting logs for integration: ${id}`))
    } finally {
      dispatch(setLoading(false))
    }
  }

export const resetHealthScore = (integration) => async (dispatch) => {
  try {
    await fetch(`${apiService.remoteHealth}/org/${integration.organization_id}`, {
      method: 'DELETE',
    })
  } catch (err) {
    dispatch(setRouteError(`Error resetting integration's health score`))
  }
  dispatch(fetchIntegration(integration.id))
}

export const fetchDistinctOutcomes = (org_id, connector_type, token) => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    const outcomes = await fetchingAPI(
      `${apiService.web}/api/outcomes?org_id=${org_id}&connector_type=${connector_type}&token=${token}`,
      'GET',
      dispatch
    )

    dispatch(setOutcomes(outcomes))
  } catch (err) {
    dispatch(setRouteError(`Error retrieving outcomes`))
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateOutcomes =
  (id_outcome_map, outcome_id_category_id_pairs, org_id, connector_type, token) =>
  async (dispatch) => {
    dispatch(setLoading(true))
    try {
      // eslint-disable-next-line prefer-const
      for (let [key, value] of Object.entries(outcome_id_category_id_pairs)) {
        let temp_value = value
        if (value === '') {
          temp_value = undefined
        }
        id_outcome_map.set(key, { ...id_outcome_map.get(key), ...{ category_id: temp_value } })
        await fetchingAPI(
          `${apiService.web}/api/outcomes/${key}?token=${token}`,
          'PUT',
          dispatch,
          JSON.stringify(id_outcome_map.get(key))
        )
      }
      dispatch(fetchDistinctOutcomes(org_id, connector_type, token))
    } catch (err) {
      dispatch(setRouteError(`Error updating outcomes: ${err}`))
    } finally {
      dispatch(setLoading(false))
    }
  }

export const fetchCategories = (token) => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    const categories = await fetchingAPI(
      `${apiService.web}/api/outcomes/categories?token=${token}`,
      'GET',
      dispatch
    )

    dispatch(setCategories(categories))
  } catch (err) {
    dispatch(setRouteError('Error retrieving categories'))
  } finally {
    dispatch(setLoading(false))
  }
}
