import moment from 'moment'
import queryString from 'query-string'
import { isNaN, cloneDeep, isEmpty } from 'lodash'

export const formatFilters = (filterValues, section) => {
  const formattedFilterValues = cloneDeep(filterValues)
  formattedFilterValues.organization = filterValues?.organization
  formattedFilterValues.scorecards = filterValues?.scorecards
  formattedFilterValues.agents = filterValues.agents?.map((x) => x.value)
  formattedFilterValues.playbooks = filterValues.playbooks?.map((x) => x.value)
  formattedFilterValues.tags = filterValues.tags?.map((x) => `${x.tag_cat}:${x.value}`)
  formattedFilterValues.challengeType = filterValues.challengeType?.map((x) => x.value)
  formattedFilterValues.challengeStatus = filterValues.challengeStatus?.map((x) => x.value)
  formattedFilterValues.categories = ['deck', 'postcall'].includes(section)
    ? filterValues[`${section}Categories`]?.map((x) => x.value)
    : []
  formattedFilterValues.startDate = filterValues.startDate
    ? moment(filterValues.startDate).startOf('day').format()
    : undefined
  formattedFilterValues.endDate = filterValues.endDate
    ? moment(filterValues.endDate).endOf('day').format()
    : undefined
  formattedFilterValues.callDuration = isEmpty(filterValues?.callDuration)
    ? 0
    : filterValues.callDuration
  formattedFilterValues.maxCallDuration = isEmpty(filterValues?.maxCallDuration)
    ? 0
    : filterValues.maxCallDuration

  const formattedQueryString = queryString.stringify({
    organization: formattedFilterValues.organization,
    agents: formattedFilterValues.agents,
    playbooks: formattedFilterValues.playbooks,
    tags: formattedFilterValues.tags,
    categories: formattedFilterValues.categories,
    call_duration: formattedFilterValues.callDuration,
    max_call_duration: formattedFilterValues.maxCallDuration,
    include_managers: formattedFilterValues.includeManagers,
    type: formattedFilterValues.challengeType,
    status: formattedFilterValues.challengeStatus,
    start_date: formattedFilterValues.startDate,
    end_date: formattedFilterValues.endDate,
  })

  return formattedQueryString
}

export const getOrganizationOptions = (organizations = []) => {
  return organizations
    .map((org) => ({ value: org.id, label: org.name }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

export const getScorecardOptions = (scorecards) => {
  if (scorecards) {
    return scorecards
      .map((scorecard) => ({
        value: scorecard.id,
        label: scorecard.name,
      }))
      .sort((a, b) => a.label?.localeCompare(b.label))
  }
  return []
}

export const getSectionOptions = (selectedScorecard) => {
  if (selectedScorecard) {
    return selectedScorecard.sections?.map((section) => ({
      value: section.id,
      label: section.name,
    }))
  }
  return []
}

export const getNestedOptions = (selectedOption, sectionName) => {
  const nestedOption = []
  if (selectedOption?.length > 0) {
    selectedOption.forEach((section) => {
      const nestedObject = { label: section.name, options: [] }
      const formatObject = section[sectionName]?.map((measure) => {
        return {
          value: measure.id,
          label: measure.name,
        }
      })
      nestedObject.options.push(...formatObject)
      nestedOption.push(nestedObject)
    })
  }
  return nestedOption
}

export const getTagOptions = (tags) => {
  return tags
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((tag) => ({ label: tag.name, value: tag.id, tag_cat: tag.tag_category_id }))
}

export const getTagsWithCategories = (tags, tagCategories) => {
  const tagsWithCategories = []

  // Created grouped tag categories
  tagCategories.forEach((cat) => {
    tagsWithCategories.push({
      label: cat.name,
      options: getTagOptions(tags.filter((tag) => tag.tag_category_id === cat.id)),
    })
  })

  // Add uncategorized tags
  tagsWithCategories.push({
    label: 'Uncategorized',
    options: getTagOptions(tags.filter((tag) => !tag.tag_category_id)),
  })

  return tagsWithCategories
}

export const getDateFormat = (date, groupByValue) => {
  switch (groupByValue) {
    case 'week':
      return `${moment.utc(date).format('M/DD')} - ${moment
        .utc(date)
        .add(6, 'days')
        .format('M/DD')}`
    case 'month':
      return moment.utc(date).format('MMMM')
    default:
      return moment.utc(date).format('M/DD')
  }
}

export function getPercentNumerical(numerator, denominator) {
  if (denominator > 0) {
    return Math.round(Math.abs((numerator / denominator) * 100))
  }

  return 0
}

export const formatPercent = (value, decimals, lessThanOne = false) => {
  if (isNaN(value) || value === 0) {
    return '0%'
  }

  if (decimals) {
    if (value < 1 && lessThanOne) {
      return '< 1%'
    }
    return `${parseFloat(value).toFixed(decimals)}%`
  }

  return `${Math.round(Math.abs(value))}%`
}

export const formatSeconds = (seconds) => {
  const mins = Math.floor(seconds / 60)
  const secs = Math.floor(seconds - mins * 60)

  return `${mins}m ${secs}s`
}

export const getChecklistPercent = (checklistData) => {
  if (checklistData.length) {
    const checklistTotals = checklistData.reduce((a, b) => {
      // eslint-disable-next-line no-return-assign, no-param-reassign
      Object.keys(b).map((c) => (a[c] = (a[c] || 0) + b[c]))

      return a
    }, {})

    if (Object.keys(checklistTotals).length > 0 && checklistTotals.total > 0) {
      return getPercentNumerical(checklistTotals.checklist_items, checklistTotals.total)
    }
  }

  return 0
}

export function explodeItemsByUser(items, users, sectionName) {
  const agents = users.reduce((acc, item) => {
    acc[item.id] = {
      first_name: item.f,
      last_name: item.l,
      ...(item.c !== 0 && { total_calls_count: item.c }),
      ...(item.c !== 0 && { average_completion_percent: getPercentNumerical(item.ce, item.cpe) }),
    }
    return acc
  }, {})

  return items.flatMap((item) =>
    item.users.map((userEntry) => ({
      [`${sectionName}_item`]: item.item,
      count: userEntry.c,
      total: userEntry.t,
      user: agents[userEntry.id],
    }))
  )
}

export const buildUsersWithAppStatus = (users, userAppStatuses) => {
  return users.map((user) => {
    const status = userAppStatuses.find((userAppStatus) => userAppStatus.user_id === user.id)

    return {
      ...user,
      app_status: status?.status,
    }
  })
}

export function truncateString(string, num) {
  if (string.length > num) {
    // eslint-disable-next-line prefer-template
    return string.slice(0, num - 3) + '...'
  }
  return string
}
export const truncateAgentName = (agentName) => {
  if (agentName) {
    const agentFirstInitial = agentName.split(' ')[0].charAt(0)
    return `${agentFirstInitial}. ${agentName.split(' ')[1]}`
  }
  return false
}

export const compareToPreviousRange = (currentData, prevData) => {
  if (!prevData) {
    return null
  }

  if (currentData === prevData) {
    return 0
  }

  const result = (Math.abs(currentData - prevData) / prevData) * 100
  const isDecimal = Math.ceil(result) !== result

  return isDecimal ? result.toFixed(2) : result
}

export const findPreviousDateRangeFromCurrentFilters = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return [startDate, endDate]
  }

  const parsedStartDate = new Date(startDate)
  const parsedEndDate = new Date(endDate)
  const numDays = parsedEndDate - parsedStartDate || 86400000 // number of milliseconds in a day, because default is one day
  const newStartDate = new Date(parsedStartDate - numDays)
  const newEndDate = new Date(parsedEndDate - numDays)

  return [newStartDate, newEndDate]
}

export const getCardVariant = (currentData, prevData) => {
  if (currentData > prevData) {
    return 'positive'
  }

  if (currentData < prevData) {
    return 'negative'
  }

  return 'neutral'
}

export const getCardVariantReverse = (currentData, prevData) => {
  if (currentData < prevData) {
    return 'positive'
  }

  if (currentData > prevData) {
    return 'negative'
  }

  return 'neutral'
}

export const formatSavedFilters = (filter, currentUser) => ({
  name: filter.name,
  is_default: filter.isDefault,
  filters: {
    organization: currentUser.organizationid,
    agents: filter.agents,
    playbooks: filter.playbooks,
    deckCategories: filter.deckCategories,
    postcallCategories: filter.postcallCategories,
    tags: filter.tags,
    includeManagers: filter.includeManagers,
    callDuration: parseInt(filter.callDuration, 10),
    maxCallDuration: filter.maxCallDuration,
    dateRange: filter.dateRange,
  },
})

const getAvg = (arr) => {
  const total = arr.reduce((acc, c) => acc + c, 0)
  return total / arr.length
}

const getSum = (arr) => {
  return arr.reduce((acc, c) => acc + c, 0)
}
// https://github.com/heofs/trendline stolen from this
// data science confirmed math is good
export const createTrend = (data, xKey, yKey) => {
  const xData = data.map((value) => value[xKey])
  const yData = data.map((value) => value[yKey])

  const xMean = getAvg(xData)
  const yMean = getAvg(yData)

  const xMinusxMean = xData.map((val) => val - xMean)
  const yMinusyMean = yData.map((val) => val - yMean)

  const xMinusxMeanSq = xMinusxMean.map((val) => val ** 2)

  const xy = []
  for (let x = 0; x < data.length; x++) {
    xy.push(xMinusxMean[x] * yMinusyMean[x])
  }

  const xySum = getSum(xy)

  const slope = xySum / getSum(xMinusxMeanSq)
  const yStart = yMean - slope * xMean

  return {
    slope,
    yStart,
    calcY: (xValue) => yStart + slope * xValue,
  }
}

export const isDateToday = (date) => {
  const todayDate = moment().format('YYYY-MM-DD')
  const REFERENCE = moment(todayDate)
  const TODAY = REFERENCE.clone().startOf('day')
  return moment(date).isSame(TODAY, 'd')
}
