import React from 'react'
import { BarsArrowUpIcon, BarsArrowDownIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'

export default ({
  label,
  dataType,
  handleClick,
  sortBy,
  asc,
  className,
  tagCategoryName,
  hideIcon,
  truncate,
  ...otherProps
}) => {
  const sorted = sortBy === dataType || sortBy === tagCategoryName
  let icon = <BarsArrowUpIcon data-testid="no-sort" />

  if (sorted) {
    if (asc) {
      icon = <BarsArrowUpIcon data-testid="ascending" />
    } else {
      icon = <BarsArrowDownIcon data-testid="descending" />
    }
  }

  return (
    <button
      type="button"
      onClick={() => handleClick && handleClick(dataType, tagCategoryName)}
      className={classNames('sort-button', className, { 'show-on-hover': !sorted })}
      {...otherProps}
    >
      <div className={classNames({ truncate })}>{label}</div>
      {!hideIcon && icon}
    </button>
  )
}
