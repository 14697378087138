import React from 'react'
import { Form, Button } from 'semantic-ui-react'

const ConfirmForm = ({
  primaryButtonText,
  handleSubmit,
  modalClose,
  bodyText,
  valueToSubmit,
  danger,
}) => {
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault()

        handleSubmit(valueToSubmit || e)
        modalClose(e)
      }}
    >
      <div style={{ margin: '0 0 1rem 0' }}>{bodyText}</div>

      <div className="modal-footer">
        <Button secondary type="button" data-testid="cancel-button" onClick={modalClose}>
          Cancel
        </Button>
        <Button type="submit" data-testid="confirm-button" color={danger ? 'red' : 'blue'}>
          {primaryButtonText}
        </Button>
      </div>
    </Form>
  )
}

export default ConfirmForm
