import React from 'react'
import { Icon, Button, Modal, Header } from 'semantic-ui-react'
import './modal.css'

export const BasicModal = ({
  title,
  onClose,
  children,
  footer,
  show,
  showCloseButton = true,
  canCloseOnDimmerClick = true,
  ...otherProps
}) => {
  return (
    <Modal
      open={Boolean(show)}
      closeOnDimmerClick={canCloseOnDimmerClick}
      data-testid="basic-modal"
      onClose={onClose}
      {...otherProps}
    >
      <Header className="basic-modal__header">
        <h1>{title}</h1>
        {showCloseButton && (
          <Button
            icon
            onClick={onClose}
            data-testid="basic-modal-close-button"
            className="basic-modal__close-button"
          >
            <Icon name="close" />
          </Button>
        )}
      </Header>
      <Modal.Content>{children}</Modal.Content>
      {Boolean(footer) && <Modal.Actions>{footer}</Modal.Actions>}
    </Modal>
  )
}
