// Action Types
export const SET_HAMTAR_RECOMMENDATIONS = 'recommendations/setHamtarRecommendations'
export const SET_TASC_RECOMMENDATIONS = 'recommendations/setTascRecommendations'
export const SET_LOADING = 'recommendations/setLoading'

// Action Creators
export const setHamtarRecommendations = (payload) => ({ type: SET_HAMTAR_RECOMMENDATIONS, payload })
export const setTascRecommendations = (payload) => ({ type: SET_TASC_RECOMMENDATIONS, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })

export const initialState = {
  hamtarRecommendations: [],
  tascRecommendations: [],
  loading: false,
}

export default function recommendationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_HAMTAR_RECOMMENDATIONS:
      return { ...state, hamtarRecommendations: action.payload }
    case SET_TASC_RECOMMENDATIONS:
      return { ...state, tascRecommendations: action.payload }
    case SET_LOADING:
      return { ...state, loading: action.payload }
    default:
      return state
  }
}
