import React from 'react'

const LoginPage = React.lazy(() => import('../views/Auth/LoginPage'))
const ResetPasswordPage = React.lazy(() => import('../views/Auth/ResetPasswordPage'))
const NewPasswordPage = React.lazy(() => import('../views/Auth/NewPasswordPage'))

export const publicRoutes = [
  { path: '/login', Component: LoginPage },
  { path: '/reset_password', Component: ResetPasswordPage },
  { path: '/temporary_links/:temporaryLink', Component: NewPasswordPage },
]
