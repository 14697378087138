/* eslint-disable */

import React from 'react'

export const ChatIcon = ({ disabled, props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 5C2 3.89543 2.89543 3 4 3H11C12.1046 3 13 3.89543 13 5V9C13 10.1046 12.1046 11 11 11H9L6 14V11H4C2.89543 11 2 10.1046 2 9V5Z"
        fill={!disabled ? '#205CAB' : '#E3E4E6'}
      />
      <path
        d="M15 7V9C15 11.2091 13.2091 13 11 13H9.82843L8.06173 14.7667C8.34154 14.9156 8.66091 15 9 15H11L14 18V15H16C17.1046 15 18 14.1046 18 13V9C18 7.89543 17.1046 7 16 7H15Z"
        fill={!disabled ? '#205CAB' : '#E3E4E6'}
      />
    </svg>
  )
}

export const CloseIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
        fill="#182337"
      />
    </svg>
  )
}

export const PhoneIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 3C2 2.44772 2.44772 2 3 2H5.15287C5.64171 2 6.0589 2.35341 6.13927 2.8356L6.87858 7.27147C6.95075 7.70451 6.73206 8.13397 6.3394 8.3303L4.79126 9.10437C5.90756 11.8783 8.12168 14.0924 10.8956 15.2087L11.6697 13.6606C11.866 13.2679 12.2955 13.0492 12.7285 13.1214L17.1644 13.8607C17.6466 13.9411 18 14.3583 18 14.8471V17C18 17.5523 17.5523 18 17 18H15C7.8203 18 2 12.1797 2 5V3Z"
        fill="#34C57C"
      />
    </svg>
  )
}

export const StartListenIcon = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.0001 2.19998C4.34913 2.19998 2.2001 4.34901 2.2001 6.99998V7.79998H3.0001C4.32558 7.79998 5.4001 8.87449 5.4001 10.2V11C5.4001 12.3255 4.32558 13.4 3.0001 13.4C1.67461 13.4 0.600098 12.3255 0.600098 11V6.99998C0.600098 3.46535 3.46548 0.599976 7.0001 0.599976C10.5347 0.599976 13.4001 3.46535 13.4001 6.99998V11C13.4001 12.3255 12.3256 13.4 11.0001 13.4C9.67461 13.4 8.6001 12.3255 8.6001 11V10.2C8.6001 8.87449 9.67461 7.79998 11.0001 7.79998H11.8001V6.99998C11.8001 4.34901 9.65106 2.19998 7.0001 2.19998Z"
        fill="#39D583"
      />
    </svg>
  )
}

export const StopListenIcon = (props) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.4 1.6C3.74903 1.6 1.6 3.74903 1.6 6.4V7.2H2.4C3.72548 7.2 4.8 8.27452 4.8 9.6V10.4C4.8 11.7255 3.72548 12.8 2.4 12.8C1.07452 12.8 0 11.7255 0 10.4V6.4C0 2.86538 2.86538 0 6.4 0C7.48403 0 8.50512 0.269514 9.3999 0.745189C10.4886 1.32394 11.3903 2.20788 11.9909 3.2829C12.5063 4.20528 12.8 5.26834 12.8 6.4V10.4C12.8 11.7255 11.7255 12.8 10.4 12.8C9.07452 12.8 8 11.7255 8 10.4V9.6C8 8.27452 9.07452 7.2 10.4 7.2H11.2V6.4C11.2 5.58846 10.9986 4.82397 10.6431 4.15376C10.18 3.28077 9.45531 2.56777 8.57357 2.11918C7.92099 1.78717 7.18235 1.6 6.4 1.6Z"
        fill="#F15050"
      />
    </svg>
  )
}

export const XIcon = (props) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.244078 0.244078C0.569515 -0.0813592 1.09715 -0.0813592 1.42259 0.244078L5 3.82149L8.57741 0.244078C8.90285 -0.0813592 9.43049 -0.0813592 9.75592 0.244078C10.0814 0.569515 10.0814 1.09715 9.75592 1.42259L6.17851 5L9.75592 8.57741C10.0814 8.90285 10.0814 9.43049 9.75592 9.75592C9.43049 10.0814 8.90285 10.0814 8.57741 9.75592L5 6.17851L1.42259 9.75592C1.09715 10.0814 0.569515 10.0814 0.244078 9.75592C-0.0813592 9.43049 -0.0813592 8.90285 0.244078 8.57741L3.82149 5L0.244078 1.42259C-0.0813592 1.09715 -0.0813592 0.569515 0.244078 0.244078Z"
        fill="#8D929C"
      />
    </svg>
  )
}

export const CheckIcon = (props) => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 5L4.75 9L11 1"
        stroke="#4D63FE"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const StopIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM6 5C5.44772 5 5 5.44772 5 6V10C5 10.5523 5.44772 11 6 11H10C10.5523 11 11 10.5523 11 10V6C11 5.44772 10.5523 5 10 5H6Z"
        fill="#a71909"
      />
    </svg>
  )
}

export const CircleIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      style={{ margin: '1px', marginRight: '5px' }}
    >
      <circle
        data-testid={`pill-banner-circle-icon-${color}`}
        cx="4"
        cy="4"
        r="4"
        className={`circle-icon-${color}`}
      />
    </svg>
  )
}
