import { cloneDeep } from 'lodash'

import { fetchingAPI, apiService } from '../api'
import { transformEntryIssues } from '../containers/config/issueHelpers'

export default ({ field, dispatch, getState, userInput, formName = null }) => {
  const entry = cloneDeep(getState().form[formName].values)

  const [topLevelProperty, nestedProperty, doubleNested, ultraNested] = field.split('.')

  // some parts of the form have the key 'decklist.entries[uuid].name' or 'trigger.phrases' etc.
  // if that's the case we have to make sure we put the input
  // in the right spot on the object
  if (ultraNested) {
    if (nestedProperty === 'decklist') {
      const doubleNestedSplit = doubleNested.slice(0, -1).split('[')
      entry[topLevelProperty][nestedProperty][doubleNestedSplit[0]][doubleNestedSplit[1]][
        ultraNested
      ] = userInput
    }
  } else if (nestedProperty) {
    entry[topLevelProperty][nestedProperty] = userInput
  } else {
    entry[topLevelProperty] = userInput
  }

  // phrases must be split into array for suggestion analysis
  if (entry.trigger && entry.trigger.phrases && typeof entry.trigger.phrases === 'string') {
    entry.trigger.phrases = entry.trigger.phrases.split(',')
  }

  // categoryEntry is both classified_postcall and deck
  // if display is undefined its classified postcall,
  // if display exists then it's part of the deck
  if (formName === 'categoryEntry' && !entry.display) {
    // eslint-disable-next-line no-param-reassign
    formName = 'classified_postcall'
  } else if (formName === 'categoryEntry' && entry.display) {
    // eslint-disable-next-line no-param-reassign
    formName = 'deck'
  }

  const { suggestionId } = entry

  // checklist and deck entries need the context of the whole section to check for overlapping triggers
  let section_entries
  if (formName === 'checklist') {
    section_entries = cloneDeep(getState().config.body.checklist.entries)
  } else if (formName === 'deck') {
    section_entries = cloneDeep(getState().config.body.deck.entries)
  }

  if (section_entries) {
    entry.existing_section_entries = section_entries
  }

  // attach language to the entry so suggestions have language context
  entry.language = getState().config.language

  fetchingAPI(
    `${apiService.web}/api/${formName}/suggestions/${suggestionId}`,
    'POST',
    dispatch,
    JSON.stringify(entry)
  )
    .then((updatedSuggestion) => {
      if (Object.keys(updatedSuggestion[suggestionId]).length > 0) {
        dispatch({
          updatedSuggestion: transformEntryIssues(updatedSuggestion, formName),
          section: formName,
          type: 'UPDATE_SUGGESTION',
        })
      } else {
        dispatch({
          suggestionId,
          section: formName,
          type: 'CLEAR_SUGGESTIONS',
        })
      }
    })
    .catch((err) => console.error('fieldSuggestionCheck failed', err))
}
