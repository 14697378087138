import moment from 'moment'
import { omit } from 'lodash'

import activityLogReducer from './realtime/activityLog/activityLog.redux'
import analyticsReducer from './analytics/analytics.redux'
import callSearchReducer from './callSearch/callSearch.redux'
import callsReducer from './calls/calls.redux'
import currentUserReducer from './auth/currentUser.redux'
import customBrandingReducer from './customBranding/customBranding.redux'
import gamificationReducer from './gamification/gamification.redux'
import insightEventsReducer from './insights/insightEvents.redux'
import integrationsReducer from './integrations/integrations.redux'
import keywordsReducer from './keywords/keywords.redux'
import leaderboardsReducer from './leaderboards/leaderboards.redux'
import organizationCustomBrandingReducer from './organizations/organizationCustomBranding.redux'
import organizationReducer from './organizations/organization.redux'
import organizationsReducer from './organizations/organizations.redux'
import organizationTagCategoriesReducer from './organizations/organizationTagCategories.redux'
import organizationTagManagerReducer from './organizations/organizationTagManager.redux'
import organizationTagsReducer from './organizations/organizationTags.redux'
import organizationUsersCSVReducer from './organizations/organizationUsersCSV.redux'
import organizationUsersReducer from './organizations/organizationUsers.redux'
import organizationVoipCampaignsReducer from './organizations/organizationVoipCampaigns.redux'
import playbookReducer from './playbooks/playbook.redux'
import playbooksReducer from './playbooks/playbooks.redux'
import realtimeAdvancedExportsReducer from './realtime/advancedExports/advancedExports.redux'
import realtimeChatsReducer from './realtime/realtimeChats.redux'
import realtimeCoachingReducer from './realtime/realtimeCoaching/realtimeCoaching.redux'
import realtimeNewAlertConfigurationReducer from './realtime/alerts/realtimeNewAlertConfiguration.redux'
import realtimeOrganizationAlertConfigurationsReducer from './realtime/alerts/realtimeOrganizationAlertConfigurations.redux'
import realtimeReportsReducer from './realtime/reports/realtimeReports.redux'
import realtimeSocketReducer from './realtime/realtimeSocket.redux'
import realtimeUserAlertsReducer from './realtime/alerts/realtimeUserAlerts.redux'
import recommendationsReducer from './recommendations/recommendations.redux'
import routeErrorsReducer from './errors/routeErrors.redux'
import scorecardsReducer from './scorecards/scorecards.redux'
import uiReducer from './ui/ui.redux'

// ordered by section then add update
const config = (
  state = {
    name: '',
    dirty: false,
    id: null,
    cid: null,
    versions: [],
    scorecardAssociations: [],
    organization_id: null,
    began_editing: null,
    active: null,
    validationError: {
      section: null,
      entry: null,
      displayName: null,
      displayMessage: false,
      readableError: null,
    },
    body: {
      checklist: { order: [], entries: {} },
      deck: {
        order: [],
        entries: {},
      },
      classified_postcall: {
        order: [],
        entries: {},
      },
      summary: { entries: {} },
      notifications: { entries: {} },
      postcall: { entries: {} },
      static: { entries: {}, order: [] },
    },
  },
  action
) => {
  switch (action.type) {
    case 'LOAD_CONFIG':
      // we dont want to destructure state here because we want it to reset
      // every time we load a new config
      return action.config
    case 'LOAD_VERSIONS':
      return { ...state, versions: action.versions }
    case 'UPDATE_CONFIG_NAME':
      return {
        ...state,
        dirty: true,
        name: action.name,
      }
    case 'ACTIVATE_CONFIG':
      return {
        ...state,
        active: true,
      }
    case 'SET_VALIDATION_ERROR':
      return {
        ...state,
        validationError: {
          section: action.section,
          entry: action.entry,
          displayMessage: true,
          displayName: action.displayName,
          readableError: action.readableError,
        },
      }
    case 'CLOSE_ERROR_MESSAGE':
      return { ...state, validationError: { ...state.validationError, displayMessage: false } }
    case 'CLEAR_VALIDATION_ERROR':
      return { ...state, validationError: { section: null, entry: null, displayMessage: false } }
    case 'ADD_ORDERED_ENTRY':
      return {
        ...state,
        dirty: true,
        body: {
          ...state.body,
          [action.section]: {
            order: [...state.body[action.section].order, action.id],
            entries: {
              ...state.body[action.section].entries,
              [action.id]: action.entry,
            },
          },
        },
      }
    case 'UPDATE_ORDERED_ENTRY':
      return {
        ...state,
        dirty: true,
        body: {
          ...state.body,
          [action.section]: {
            ...state.body[action.section],
            entries: {
              ...state.body[action.section].entries,
              [action.id]: action.entry,
            },
          },
        },
      }
    case 'REORDER_ORDERED_ENTRY':
      return {
        ...state,
        dirty: true,
        body: {
          ...state.body,
          [action.section]: {
            ...state.body[action.section],
            order: action.order,
          },
        },
      }
    case 'DELETE_ORDERED_ENTRY':
      return {
        ...state,
        dirty: true,
        body: {
          ...state.body,
          [action.section]: {
            order: action.order,
            entries: action.entries,
          },
        },
      }
    case 'ADD_CATEGORY':
      return {
        ...state,
        dirty: true,
        body: {
          ...state.body,
          [action.section]: {
            order: [...state.body[action.section].order, action.id],
            entries: {
              ...state.body[action.section].entries,
              [action.id]: {
                name: action.name,
                order: [],
                entries: {},
              },
            },
          },
        },
      }
    case 'UPDATE_CATEGORY':
      return {
        ...state,
        dirty: true,
        body: {
          ...state.body,
          [action.section]: {
            ...state.body[action.section],
            entries: {
              ...state.body[action.section].entries,
              [action.id]: {
                ...state.body[action.section].entries[action.id],
                name: action.name,
              },
            },
          },
        },
      }
    case 'DELETE_CATEGORY':
      return {
        ...state,
        dirty: true,
        body: {
          ...state.body,
          [action.section]: {
            ...state.body[action.section],
            order: action.order,
            entries: action.entries,
          },
        },
      }
    case 'ADD_CATEGORY_ENTRY':
      return {
        ...state,
        dirty: true,
        body: {
          ...state.body,
          [action.section]: {
            ...state.body[action.section],
            entries: {
              ...state.body[action.section].entries,
              [action.categoryID]: {
                ...state.body[action.section].entries[action.categoryID],
                order: [...state.body[action.section].entries[action.categoryID].order, action.id],
                entries: {
                  ...state.body[action.section].entries[action.categoryID].entries,
                  [action.id]: action.entry,
                },
              },
            },
          },
        },
      }
    case 'UPDATE_CATEGORY_ENTRY':
      return {
        ...state,
        dirty: true,
        body: {
          ...state.body,
          [action.section]: {
            ...state.body[action.section],
            entries: {
              ...state.body[action.section].entries,
              [action.categoryID]: {
                ...state.body[action.section].entries[action.categoryID],
                entries: {
                  ...state.body[action.section].entries[action.categoryID].entries,
                  [action.id]: action.entry,
                },
              },
            },
          },
        },
      }
    case 'DELETE_CATEGORY_ENTRY':
      return {
        ...state,
        dirty: true,
        body: {
          ...state.body,
          [action.section]: {
            ...state.body[action.section],
            entries: {
              ...state.body[action.section].entries,
              [action.categoryID]: {
                ...state.body[action.section].entries[action.categoryID],
                order: action.order,
                entries: action.entries,
              },
            },
          },
        },
      }
    case 'ADD_UNORDERED_ENTRY':
    case 'UPDATE_UNORDERED_ENTRY':
      return {
        ...state,
        dirty: true,
        body: {
          ...state.body,
          [action.section]: {
            ...state.body[action.section],
            entries: {
              ...state.body[action.section].entries,
              [action.id]: action.entry,
            },
          },
        },
      }
    case 'DELETE_UNORDERED_ENTRY':
      return {
        ...state,
        dirty: true,
        body: {
          ...state.body,
          [action.section]: {
            entries: action.entries,
          },
        },
      }
    case 'REORDER_CATEGORY':
      return {
        ...state,
        dirty: true,
        body: {
          ...state.body,
          [action.section]: {
            ...state.body[action.section],
            order: action.order,
          },
        },
      }
    case 'REORDER_CATEGORY_ENTRY':
      return {
        ...state,
        dirty: true,
        body: {
          ...state.body,
          [action.section]: {
            ...state.body[action.section],
            entries: {
              ...state.body[action.section].entries,
              [action.categoryID]: {
                ...state.body[action.section].entries[action.categoryID],
                order: action.order,
              },
            },
          },
        },
      }
    case 'MOVE_CATEGORY_ENTRY':
      return {
        ...state,
        dirty: true,
        body: {
          ...state.body,
          [action.section]: {
            ...state.body[action.section],
            entries: {
              ...state.body[action.section].entries,
              [action.sourceCategoryID]: {
                ...state.body[action.section].entries[action.sourceCategoryID],
                order: action.sourceOrder,
                entries: action.sourceEntries,
              },
              [action.destinationCategoryID]: {
                ...state.body[action.section].entries[action.destinationCategoryID],
                order: action.destinationOrder,
                entries: action.destinationEntries,
              },
            },
          },
        },
      }
    case 'RESTORE_CONFIG':
      return { ...state, body: action.body, validationError: { section: null, entry: null } }
    case 'UPDATE_CONFIG_BODY':
      return {
        ...state,
        dirty: true,
        body: action.body,
        validationError: { section: null, entry: null },
      }
    default:
      return state
  }
}

const configs = (state = [], action) => {
  switch (action.type) {
    case 'LOAD_CONFIGS':
      return action.configs.slice(0)
    default:
      return state
  }
}

const aWeekAgo = moment().subtract(6, 'd').startOf('day').format()
const endOfToday = moment().endOf('day').format()

const reportFilters = (
  state = {
    checklist: {
      configCid: null,
      includeManagers: false,
      startDate: aWeekAgo,
      endDate: endOfToday,
      callDuration: 1,
      categoryID: null,
      selectedTags: [],
    },
    decklist: {
      configCid: null,
      includeManagers: false,
      startDate: aWeekAgo,
      endDate: endOfToday,
      callDuration: 1,
      categoryID: null,
      selectedTags: [],
    },
    notifications: {
      configCid: null,
      includeManagers: false,
      startDate: aWeekAgo,
      endDate: endOfToday,
      callDuration: 1,
      categoryID: null,
      selectedTags: [],
    },
    classified_postcall: {
      configCid: null,
      includeManagers: false,
      startDate: aWeekAgo,
      endDate: endOfToday,
      callDuration: 1,
      categoryID: null,
      selectedTags: [],
      appliedConfig: null,
    },
    csv: {
      configCid: null,
      includeManagers: false,
      startDate: aWeekAgo,
      endDate: endOfToday,
      callDuration: 1,
      selectedTags: [],
    },
    usage: {
      configCid: null,
      includeManagers: false,
      startDate: aWeekAgo,
      endDate: endOfToday,
      callDuration: 1,
      selectedTags: [],
    },
    // only uses cid, and selectedTags, on the back end rest are here,
    // just to keep with the pattern. Endpoints operate on fixed date ranges
    // durations etc.
    troubleshooting: {
      configCid: null,
      includeManagers: false,
      startDate: aWeekAgo,
      endDate: endOfToday,
      callDuration: 1,
      selectedTags: [],
    },
    callDuration: 1,
    organizationId: null,
    configCid: null,
    includeManagers: false,
    categoryID: null,
    startDate: aWeekAgo,
    endDate: endOfToday,
    config: null,
    selectedTags: [],
    metadata: {},
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_CALL_DURATION':
      return { ...state, callDuration: action.callDuration }
    case 'UPDATE_ORGANIZATION_ID':
      return {
        ...state,
        organizationId: action.organizationId,
        configCid: null,
        config: null,
        categoryID: null,
        selectedTags: [],
        metadata: {},
      }
    case 'UPDATE_CONFIG_FILTER':
      return { ...state, configCid: action.configCid, categoryID: null }
    case 'UPDATE_RANGE':
      return { ...state, startDate: action.startDate, endDate: action.endDate }
    case 'UPDATE_CATEGORY_ID':
      return { ...state, categoryID: action.categoryID }
    case 'CLEAR_CATEGORY_ID':
      return { ...state, categoryID: null }
    case 'UPDATE_SELECTED_TAGS':
      return { ...state, selectedTags: action.tags }
    case 'UPDATE_SELECTED_METADATA':
      return { ...state, metadata: action.metadata }
    case 'UPDATE_APPLIED_FILTERS':
      return {
        ...state,
        [action.section]: {
          configCid: state.configCid,
          startDate: state.startDate,
          endDate: state.endDate,
          callDuration: state.callDuration,
          categoryID: state.categoryID,
          includeManagers: state.includeManagers,
          appliedConfig: state.config,
          metadata: state.metadata,
        },
      }
    case 'CLEAR_CSV_CONFIG':
      return { ...state, config: null }
    case 'LOAD_CSV_CONFIG':
      return { ...state, config: action.config }
    case 'UPDATE_INCLUDE_MANAGERS':
      return { ...state, includeManagers: action.includeManagers }
    default:
      return state
  }
}

const reportData = (
  state = {
    checklist: {
      configName: null,
      overTimeData: [],
      usageByUserData: [],
      userUsageData: [],
      tableViewData: [],
      userName: null,
      legendItems: [],
    },
    decklist: {
      configName: null,
      overTimeData: [],
      usageByUserData: [],
      userUsageData: [],
      tableViewData: [],
      legendItems: [],
      winRateTable: [],
      winItem: null,
    },
    notifications: {
      configName: null,
      overTimeData: [],
      usageByUserData: [],
      userUsageData: [],
      tableViewData: [],
      userName: null,
      legendItems: [],
    },
    classified_postcall: {
      configName: null,
      overTimeData: [],
      usageByUserData: [],
      userUsageData: [],
      tableViewData: [],
      userName: null,
      legendItems: [],
    },
    usage: {
      configName: null,
      overTimeData: [],
      usageByUserData: { usage: [], missingUsers: [] },
      userUsageData: [],
      userName: null,
      legendItems: [],
    },
    csv: {
      callsDownloaded: 0,
    },
    troubleshooting: {
      newAgentsNoCalls: {
        users: {},
        tagCategories: [],
      },
      inactiveAgents: {
        users: {},
        tagCategories: [],
      },
      newAgentsNoLogins: {
        users: {},
        tagCategories: [],
      },
    },
  },
  action
) => {
  switch (action.type) {
    case 'LOAD_INITIAL_DATA':
      return {
        ...state,
        [action.section]: {
          ...state[action.section],
          legendItems: action.legendItems,
          configName: action.configName,
          overTimeData: action.overTimeData,
          usageByUserData: action.usageByUserData,
          tableViewData: action.tableViewData,
          userUsageData: [],
          userName: null,
        },
      }
    case 'LOAD_USAGE_BY_USER_DATA':
      return {
        ...state,
        [action.section]: {
          ...state[action.section],
          legendItems: action.legendItems,
          configName: action.configName,
          tableViewData: action.tableViewData,
          usageByUserData: action.usageByUserData,
        },
      }
    case 'UPDATE_LEGEND_ITEMS':
      return {
        ...state,
        [action.section]: {
          ...state[action.section],
          legendItems: action.legendItems,
        },
      }
    case 'LOAD_GRAPH_DATA':
      return {
        ...state,
        [action.section]: {
          ...state[action.section],
          [action.graphType]: action.graphData,
          userName: action.userName,
        },
      }
    case 'LOAD_TROUBLESHOOTING_DATA':
      return {
        ...state,
        troubleshooting: {
          newAgentsNoCalls: {
            users: action.newAgentsNoCalls.users,
            tagCategories: action.newAgentsNoCalls.tagCategories,
          },
          inactiveAgents: {
            users: action.inactiveAgents.users,
            tagCategories: action.inactiveAgents.tagCategories,
          },
          newAgentsNoLogins: {
            users: action.newAgentsNoLogins.users,
            tagCategories: action.newAgentsNoLogins.tagCategories,
          },
        },
      }
    case 'UPDATE_CALLS_DOWNLOADED':
      return {
        ...state,
        csv: {
          callsDownloaded: action.count,
        },
      }
    case 'UPDATE_DECKLIST_WIN_RATE':
      return {
        ...state,
        decklist: {
          ...state.decklist,
          winItem: action.winItem,
          winRateTable: action.winRateTable,
          oldDecklistEntries: action.oldDecklistEntries,
        },
      }
    default:
      return state
  }
}

const reportVersions = (
  state = {
    startDate: null,
    endDate: null,
    configCid: null,
    versionIds: [],
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_VERSION_IDS':
      return {
        ...state,
        versionIds: action.versionIds,
        configCid: action.configCid,
        startDate: action.startDate,
        endDate: action.endDate,
      }
    default:
      return state
  }
}

const suggestions = (
  state = {
    loading: false,
    checklist: {
      entries: {},
      section_suggestions: {},
    },
    classified_postcall: {
      entries: {},
      section_suggestions: {},
    },
    deck: {
      entries: {},
      section_suggestions: {},
    },
    notifications: {
      entries: {},
      section_suggestions: {},
    },
    postcall: {
      entries: {},
      section_suggestions: {},
    },
    static: {
      entries: {},
      section_suggestions: {},
    },
    summary: {
      entries: {},
      section_suggestions: {},
    },
  },
  action
) => {
  switch (action.type) {
    case 'LOAD_SUGGESTIONS':
      return {
        ...state,
        ...action.suggestions,
      }
    case 'TOGGLE_ON_LOADING_SUGGESTIONS':
      return {
        ...state,
        loading: true,
      }
    case 'TOGGLE_OFF_LOADING_SUGGESTIONS':
      return {
        ...state,
        loading: false,
      }
    case 'UPDATE_SUGGESTION':
      return {
        ...state,
        [action.section]: {
          section_suggestions: {
            ...state[action.section].section_suggestions,
          },
          entries: {
            ...state[action.section].entries,
            ...action.updatedSuggestion,
          },
        },
      }
    case 'UPDATE_SECTION_SUGGESTIONS':
      return {
        ...state,
        [action.sectionName]: {
          ...action.updatedSuggestions,
        },
      }
    case 'CLEAR_SUGGESTIONS':
      return {
        ...state,
        [action.section]: {
          ...state[action.section],
          entries: {
            ...omit(state[action.section].entries, action.suggestionId),
          },
        },
      }
    default:
      return state
  }
}

const reducers = {
  activityLog: activityLogReducer,
  analytics: analyticsReducer,
  calls: callsReducer,
  callSearch: callSearchReducer,
  config,
  configs,
  currentUser: currentUserReducer,
  customBranding: customBrandingReducer,
  gamification: gamificationReducer,
  insightEvents: insightEventsReducer,
  integrations: integrationsReducer,
  keywords: keywordsReducer,
  leaderboards: leaderboardsReducer,
  organization: organizationReducer,
  organizationCustomBranding: organizationCustomBrandingReducer,
  organizations: organizationsReducer,
  organizationTagCategories: organizationTagCategoriesReducer,
  organizationTagManager: organizationTagManagerReducer,
  organizationTags: organizationTagsReducer,
  organizationUsers: organizationUsersReducer,
  organizationUsersCSV: organizationUsersCSVReducer,
  organizationVoipCampaigns: organizationVoipCampaignsReducer,
  playbook: playbookReducer,
  playbooks: playbooksReducer,
  realtimeAdvancedExports: realtimeAdvancedExportsReducer,
  realtimeChats: realtimeChatsReducer,
  realtimeCoaching: realtimeCoachingReducer,
  realtimeNewAlertConfiguration: realtimeNewAlertConfigurationReducer,
  realtimeOrganizationAlertConfigurations: realtimeOrganizationAlertConfigurationsReducer,
  realtimeReports: realtimeReportsReducer,
  realtimeSocket: realtimeSocketReducer,
  realtimeUserAlerts: realtimeUserAlertsReducer,
  recommendations: recommendationsReducer,
  reportData,
  reportFilters,
  reportVersions,
  routeErrors: routeErrorsReducer,
  scorecards: scorecardsReducer,
  suggestions,
  ui: uiReducer,
}

export default reducers
