// Action Types
export const SET_ORGANIZATION = 'organizations/setOrganization'

// Action Creators
export const setOrganization = (payload) => ({ type: SET_ORGANIZATION, payload })

// State
export const initialState = {}

// Reducer
export default function organizationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ORGANIZATION:
      return action.payload
    default:
      return state
  }
}
